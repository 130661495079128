import { useEffect, useState } from 'react';
import { GetAllSectionsByType } from '../../../services/sectionService';
import AdminBanners from '../admin-banner'
import style from './style.module.css'
import { Seccion } from '../../../interfaces/seccion';
import CardBannerAdmin from '../../../components/cardBannerAdmin';
import { useForm } from 'react-hook-form';
import { GetParametroByClave, UpdateParametro } from '../../../services/parametroService';
import SpinnerLoading from '../../../components/spinner';
import MyModalInfo from '../../../components/modalInfo';

export default function AdminHomepage(){
    const [banners, setBanners] = useState<Seccion[]>();
    const [metrosConstruidos, setMetrosConstruidos] = useState<string>();
    const [edificiosConstruidos, setEdificiosConstruidos] = useState<string>();
    const [departamentosEntregados, setDepartamentosEntregados] = useState<string>();
    const [aniosTrayectoria, setAniosTrayectoria] = useState<string>();
    const [banner, setBanner] = useState<Seccion>();
    const [edit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBanners();
        getCantidades();
    }, []);

    const getCantidades = async () => {
        let _metrosConstruidos = await GetParametroByClave('MetrosConstruidos');
        let _departamentosEntregados = await GetParametroByClave('DepartamentosEntregados');
        let _edificiosConstruidos = await GetParametroByClave('EdificiosConstruidos');
        let _aniosTrayectoria = await GetParametroByClave('AñosDeTrayectoria');
        setValue("inputMetrosConstruidos", _metrosConstruidos?.valor || "");
        setValue("inputDepartamentosEntregados", _departamentosEntregados?.valor || "");
        setValue("inputEdificiosConstruidos", _edificiosConstruidos?.valor || "");
        setValue("inputAniosTrayectoria", _aniosTrayectoria?.valor || "");
        if (_metrosConstruidos)setMetrosConstruidos(_metrosConstruidos?.valor);
        if (_departamentosEntregados)setDepartamentosEntregados(_departamentosEntregados?.valor);
        if (_edificiosConstruidos)setEdificiosConstruidos(_edificiosConstruidos?.valor);
        if (_aniosTrayectoria)setAniosTrayectoria(_aniosTrayectoria?.valor);
    }
    
    const getBanners = async () => {
        let banners = await GetAllSectionsByType(1);
        setBanners(banners);
    }

    const editBanner = async (element: Seccion) => {
        setBanner(element);
        setEdit(true);
    }

    const goBack = () => {
        setEdit(false);
    }

    const newBanner = () => {
        setBanner(undefined);
        setEdit(true);
    }
    
    const form = useForm({
        defaultValues: {
            inputMetrosConstruidos: metrosConstruidos || "",
            inputDepartamentosEntregados: departamentosEntregados,
            inputEdificiosConstruidos: edificiosConstruidos,
            inputAniosTrayectoria: aniosTrayectoria,
        }
    });

    const guardar = async (formData: any) => {
        setLoading(true);
        formData = {
            ...formData,            
        }
        let resultModified = false;
        try{
            if (formData.inputMetrosConstruidos != metrosConstruidos) {
                let result = await UpdateParametro({clave: "MetrosConstruidos", valor: formData.inputMetrosConstruidos})
                resultModified = result!;
            };
            if (formData.inputDepartamentosEntregados != departamentosEntregados) {
                let result = await UpdateParametro({clave: "DepartamentosEntregados", valor: formData.inputDepartamentosEntregados}) 
                resultModified = result!;
            };
            if (formData.inputEdificiosConstruidos != edificiosConstruidos) {
                let result = await UpdateParametro({clave: "EdificiosConstruidos", valor: formData.inputEdificiosConstruidos }) 
                resultModified = result!;
            };
            if (formData.inputAniosTrayectoria != aniosTrayectoria) {
                let result = await UpdateParametro({clave: "AñosDeTrayectoria", valor: formData.inputAniosTrayectoria}) 
                resultModified = result!;
            };

            if (resultModified){
                let btnConfirmar = document.getElementById(`btnModalAdministracionCantidades`);
                btnConfirmar?.click();
            }
        }
        catch (error: any) {
           console.log(error) 
        }
   
        setLoading(false);    
    }

    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;

    const renderBanners = () => banners?.map((v, i) => <div className="col-12 col-md-4 my-2" key={i}><CardBannerAdmin info={v} editBanner={editBanner} tipoSeccion="Banner"></CardBannerAdmin></div>)

    return (
        <div className={style.adminHomepage}>
               <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h2 className={style.title}>ADMINISTRACIÓN HOMEPAGE</h2>
           
            </div>
            <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h4 className={style.title}>CANTIDADES</h4>        
            </div>
            <div>
                <form onSubmit={handleSubmit(guardar)}>
                    <section className="col-12 col-md-12 col-lg-6 px-1">
                        <ul>
                            <li className="d-flex align-items-center gap-3">
                                <label htmlFor="info" className={style.labelText}>Metros Construidos </label>
                                <input type="number" {...register("inputMetrosConstruidos")} id="inputMetrosConstruidos" placeholder="Ingrese un valor" 
                                    className={`form-control my-2 border border-primary ${style.formControlText}`} />                
                            </li>
                            {errors.inputMetrosConstruidos?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}   
                            <li className="d-flex align-items-center gap-3" hidden>
                                <label htmlFor="info" className={style.labelText}>Departamentos Entregados</label>
                                <input type="number" {...register("inputDepartamentosEntregados")} id="inputDepartamentosEntregados" placeholder="Ingrese un valor" 
                                    className={`form-control my-2 border border-primary ${style.formControlText}`} />                   
                            </li>
                            {errors.inputDepartamentosEntregados?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}                               
                            <li className="d-flex align-items-center gap-3">
                                <label htmlFor="info" className={style.labelColor}>Edificios Construidos</label>
                                <input type="number" {...register("inputEdificiosConstruidos")} id="inputEdificiosConstruidos" placeholder="Ingrese un valor" 
                                    className={`form-control my-2 border border-primary ${style.formControlText}`} />
                            </li>
                            {errors.inputEdificiosConstruidos?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}      
                            <li className="d-flex align-items-center gap-3">
                                <label htmlFor="info" className={style.labelColor}>Años de Trayectoria</label>
                                <input type="number" {...register("inputAniosTrayectoria")} id="inputAniosTrayectoria" placeholder="Ingrese un valor" 
                                    className={`form-control my-2 border border-primary ${style.formControlText}`} />
                            </li>
                            {errors.inputAniosTrayectoria?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}                        
                        </ul>
                        <div className="col-md-12 text-center my-5 gap-3">
                            <button type="submit" id="colorsSubmit" className={`${style.boton}`}>Guardar</button>
                        </div>  
                    </section>
                </form> 
            </div>
            <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h4 className={style.title}>BANNERS</h4>
                {!edit?<button className={style.btnAgregar} onClick={newBanner}>
                    Agregar
                </button>:''}
            </div>            
            {!edit?<div className="row mb-3">
                {banners? renderBanners():''}
            </div>:''}
            {edit?<div>
                <AdminBanners data={banner} goBack={goBack} tipoSeccion="Banner"></AdminBanners> 
            </div>:''}
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
            <MyModalInfo idButton={`btnModalAdministracionCantidades`} titulo={"Confirmacion"} mensaje={`Cantidades actualizadas con éxito`} headerColor="success" show={true}></MyModalInfo>
        </div>
    )
}