import style from   "./style.module.css"
import Header from "../../components/header";
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";
import ReturnTop from "../../components/return-top";
import { Info } from "../../interfaces/info";
import { MockInversiones } from "../../mocks/inversiones";
import SectionTextLeft from "../../components/sectionTextLeft";
import SectionTextRight from "../../components/sectionTextRight";
import TopSection from "./top-section";
import { GetAllSectionsByType } from "../../services/sectionService";
import { Seccion } from "../../interfaces/seccion";
import WhatsappButton from "../../components/whatsapp-button";
import { useLocation } from "react-router";
import SpinnerLoading from "../../components/spinner";

export default function Inversiones(){
    const [styleTop, setStyleTop] = useState(false);
    const [alquileres, setAlquileres] = useState<Seccion[]>();
    const { search } = useLocation();
    const [loading, setLoading] = useState(false);
    const id = new URLSearchParams(search).get("id");

    useEffect(() => {
        fetchAlquileres();        
        
        
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        // });
        // window.addEventListener('scroll', onScroll)
        // return () => {
        //   window.removeEventListener('scroll', onScroll)
        // }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }

    const fetchAlquileres = async () => {
        setLoading(true);
        let banners = await GetAllSectionsByType(4);
        setAlquileres(banners);
        setTimeout(() => {
            document.querySelector(`#${id}`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            setLoading(false);
        }, 300);
    }

    const infoList: Info[] =  MockInversiones;
    
    const navItemActive = NavItemActive.Fideicomisos;
    return (
        <div className={style.sectionInversiones}>
            <Header navItemActive={navItemActive}></Header>
            <TopSection></TopSection>
            <SectionTextLeft info={infoList[0]} contacto={true} buttonLink="obras" buttonText="VER PROYECTOS" idSeccion="inversiones01"></SectionTextLeft>
            <SectionTextRight info={infoList[1]} contacto={true} idSeccion="inversiones02"></SectionTextRight>
            {alquileres?<SectionTextLeft info={infoList[2]} contacto={true} imagenesCarousel={alquileres} idSeccion="inversiones03"></SectionTextLeft>:''}
            <Footer></Footer>
            <Copyright></Copyright>
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </div>
    )
}