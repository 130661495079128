import { useForm } from 'react-hook-form';
import style from './style.module.css'
import { useEffect, useState } from 'react';
import logo from '../../../media/logo.jpg'
import { Parametro } from '../../../interfaces/parametro';
import { GetAllParametros, UpdateParametro } from '../../../services/parametroService';
import { ClaveValor } from '../../../interfaces/claveValor';

export default function AdminGeneral(){
    const [colors, setColors] = useState<Parametro>();
    const [parametros, setParametros] = useState<ClaveValor[]>();
    let colorsStorage = localStorage.getItem("parametros")? JSON.parse(localStorage.getItem("parametros")!): {};
    
    useEffect(() => {
        //getParametros();
        setColors(colorsStorage);
    }, []);  

    const getParametros = async () => {
        let parametros = await GetAllParametros();
        setParametros(parametros);
    }

    const form = useForm({
        defaultValues: {
            nombre: colors?.nombre || colorsStorage.nombre,
            correo: colors?.correo || colorsStorage.correo,
            firstColor: colors?.firstColor || colorsStorage.firstColor,
            secondColor: colors?.secondColor || colorsStorage.secondColor,
            thirdColor: colors?.thirdColor || colorsStorage.thirdColor,
            fourthColor: colors?.fourthColor || colorsStorage.fourthColor,
            fifthColor: colors?.fifthColor || colorsStorage.fifthColor,
            bgColor: colors?.bgColor || colorsStorage.bgColor,
        }
    });


    const guardar = async (formData: any) => {
        let colorsUpdate = [];
        formData = {
            ...formData,            
        }
        //console.log(formData)
        if (formData.firstColor != colorsStorage.firstColor) { colorsUpdate.push({ clave: 'FirstColor', valor: formData.firstColor }) };
        if (formData.secondColor != colorsStorage.secondColor) { colorsUpdate.push({ clave: 'SecondColor', valor: formData.secondColor }) };
        if (formData.thirdColor != colorsStorage.thirdColor) { colorsUpdate.push({ clave: 'ThirdColor', valor: formData.thirdColor }) };
        if (formData.fourthColor != colorsStorage.fourthColor) { colorsUpdate.push({ clave: 'FourthColor', valor: formData.fourthColor }) };
        if (formData.fifthColor != colorsStorage.fifthColor) { colorsUpdate.push({ clave: 'FifthColor', valor: formData.fifthColor }) };
        if (formData.bgColor != colorsStorage.bgColor) { colorsUpdate.push({ clave: 'BackgroundColor', valor: formData.bgColor }) };        
        if (formData.correo != colorsStorage.correo) { colorsUpdate.push({ clave: 'Correo', valor: formData.correo }) };        

        for (let index = 0; index < colorsUpdate.length; index++) {            
            await UpdateParametro(colorsUpdate[index]);            
        }
        
        setColors(formData);
        localStorage.setItem("parametros", JSON.stringify(formData));     
        window.location.reload();
    }

    const reset = () => {
        localStorage.removeItem("parametros");
        window.location.reload();
    }
    
    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;

    return (
        <div className={style.colorsBox}>
            <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h2>ADMINISTRACIÓN GENERAL</h2>
            </div>
            <form onSubmit={handleSubmit(guardar)}>
                <section className="col-12 col-md-12 col-lg-10 px-1">
                    <ul>
                        <li className="d-flex align-items-center gap-3 d-none">
                            <label htmlFor="info" className={style.labelText}>NOMBRE</label>
                            <input type="text" {...register("nombre")} id="correo" placeholder="Ingrese un correo" 
                                className={`form-control my-2 border border-primary ${style.formControlText}`} />
                            <div className={style.logoContainer}>
                                <span className={style.spanText}>{colors?.nombre}</span>
                            </div>
                        </li>                        
                        {errors.nombre?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}     
                        {/* <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelText}>LOGO</label>
                            <input type="file" {...register("logo")} id="logo" placeholder="Seleccione un logo" 
                                className={`form-control my-2 border border-primary ${style.formControlText}`} />
                            <div className={style.logoContainer}>
                                <img src={logo} alt="LogoSelected" />
                            </div>
                        </li>
                        {errors.logo?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}    */}
                        <li className="d-flex gap-3 align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>PRIMERO</label>
                            <input type="color" {...register("firstColor")} id="firstColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.firstColor}</span>
                        </li>
                        {errors.firstColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>SEGUNDO</label>
                            <input type="color" {...register("secondColor")} id="secondColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.secondColor}</span>
                        </li>
                        {errors.secondColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}    
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>TERCERO</label>
                            <input type="color" {...register("thirdColor")} id="thirdColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.thirdColor}</span>
                        </li>
                        {errors.thirdColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}    
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>CUARTO</label>
                            <input type="color" {...register("fourthColor")} id="fourthColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.fourthColor}</span>
                        </li>
                        {errors.fourthColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}               
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>QUINTO</label>
                            <input type="color" {...register("fifthColor")} id="fifthColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.fifthColor}</span>
                        </li>
                        {errors.fifthColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}
                        <li className="d-flex align-items-center gap-3 d-none">
                            <label htmlFor="info" className={style.labelColor}>BACKGROUND</label>
                            <input type="color" {...register("bgColor")} id="bgColor" placeholder="Ingrese un color" 
                                className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                            <span className={style.spanText}>{colors?.bgColor}</span>
                        </li>
                        {errors.bgColor?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}    
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="info" className={style.labelColor}>CORREO</label>
                            <input type="email" {...register("correo")} id="correo" placeholder="Ingrese un correo" 
                                className={`form-control my-2 border border-primary ${style.formControlNombreCorreo}`} />
                            <span className={style.spanCorreo}>{colors?.correo}</span>
                        </li>
                        {errors.correo?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}   
                    </ul>
                    <div className="col-md-12 text-center my-5 gap-3">
                        <button type="button" className="bg-warning mx-3" onClick={reset} >Restaurar</button>
                        <button type="submit" id="colorsSubmit" className={`${style.boton}`}>Guardar</button>
                    </div>  
                </section>
            </form>
        </div>
    )
}