import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import { MockEtapas } from "../../../mocks/etapas";
import { Etapa } from "../../../interfaces/etapa";
import CardEtapa from "../../../components/cardEtapa";

interface DetailSectionProps{
    data: Obra;
    onClickEtapaSection?: any;   
}

export default function DetailProgress({data, onClickEtapaSection}: DetailSectionProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [etapas, setEtapas] = useState<Etapa[]>();

    useEffect(() => {
        let listaEtapas = MockEtapas;
        setEdificio(data);  
        setEtapas(listaEtapas);      
    }, [data]);  

    const onClickEtapaProgress = (event: any)  => {
        let etapasLength = etapas?.length;
        for (let index = 1; index <= etapasLength!; index++) {            
            let divElement = document.getElementById(index.toString());
            if(index <= event){
                divElement?.classList.add(style.active)
            }
            else{
                divElement?.classList.remove(style.active)
            }
        }   
        onClickEtapaSection(event);
    }


    const renderProgress = () => etapas?.map((v, i) => <CardEtapa data={v} key={i} 
                                                etapaSuperada={(v?.id || 0) > (edificio?.etapaObraId || 0)} ultimaEtapa={i+1<etapas.length}
                                                onClickEtapa={onClickEtapaProgress} ></CardEtapa>)

    return (
            <div className={style.progressContainer}>
                {etapas?renderProgress():''}
            </div>  
    )
}