import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import style from "./style.module.css";
import { GetParametroByClave } from "../../services/parametroService";
import { SendMail } from "../../services/mailService";
import SpinnerLoading from "../spinner";
import MyModalInfo from "../modalInfo";

interface ConsultaProps{
    data: string;
}

export default function Consulta({data}: ConsultaProps){
    const [message, setMessage] = useState(data);
    const [loading, setLoading] = useState(false);

    const { register, formState: {errors}, watch, handleSubmit } = useForm({
        defaultValues: {
            Nombre: '',
            Apellido: '',
            Email: '',
            Whatsapp: '',
            Destinatario: '',
            Asunto: 'Consulta Formulario Web',
            Mensaje: data,
            Datetime: ''
        }
    });

    useEffect(() => {
        setMessage(data);        
    }, []);  

    const enviarContacto = async (formData: any) => {
        setLoading(true);
        let destinatario = await GetParametroByClave('CORREO');
        let today = new Date();
        formData = {
            ...formData,
            Destinatario: destinatario?.valor,
            Datetime: today.toISOString()
        }
        //let response = await SendMail(formData);
        let response =  SendMail(formData);

        setTimeout(() => {
            let btnConfirmar = document.getElementById(`btnModalEmail`);
            btnConfirmar?.click();
            setLoading(false);
        }, 500);
        // if(response){
        //     let btnConfirmar = document.getElementById(`btnModalEmail`);
        //     btnConfirmar?.click();
        // }
        //setLoading(false);
    }

    return (
        <section className={style.formSection}>
            <div className="container-fluid">
                <div className={style.sectionTitle}>                   
                    <h2>Asesoramiento personalizado</h2>
                </div>
                <form onSubmit={handleSubmit(enviarContacto)}>
                    <div className="row">
                        <div className="col-md-6">
                            <input type="text" {...register("Nombre", {required: true})} id="contacto_nombre" placeholder="* Nombre" className={`form-control ${style.formControl}`} />
                            {errors.Nombre?.type === 'required' && <p className="text-danger"> El campo nombre es requerido</p>}
                        </div>
                        <div className="col-md-6">
                            <input type="text" {...register("Apellido", {required: true})} id="contacto_apellido" placeholder="* Apellido" className={`form-control ${style.formControl}`} />
                            {errors.Apellido?.type === 'required' && <p className="text-danger"> El campo apellido es requerido</p>}
                        </div>
                        <div className="col-md-6">
                            <input type="text" {...register("Whatsapp")} id="contacto_telefono" placeholder="WhatsApp (sin 0 ni 15)" className={`form-control ${style.formControl}`} />
                        </div>
                        <div className="col-md-6">
                            <input type="email" {...register("Email", {required: true})} id="contacto_email" placeholder="* Email" className={`form-control ${style.formControl}`} />
                            {errors.Email?.type === 'required' && <p className="text-danger"> El campo email es requerido</p>}
                        </div>
                        <div className="col-md-12">
                            <textarea id="contacto_mensaje" {...register("Mensaje")} placeholder="Escriba aquí su mensaje" className={`form-control ${style.formControl}`}></textarea>
                        </div>
                        <div className="col-md-12 text-center">
                            <button type="submit" id="contacto_submit" className={`${style.boton}`}>ENVIAR CONSULTA</button>
                        </div>
                    </div>
                </form>
            </div>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
            <MyModalInfo idButton={`btnModalEmail`} titulo={"Confirmación"} mensaje={`Email enviado con éxito`} headerColor="success" show={true}></MyModalInfo>
        </section>
    )
}