import style from "./style.module.css";
import { useEffect, useState } from "react"
import { Info } from "../../interfaces/info";
import CardEdificio from "../cardEdificio";
import { Obra } from "../../interfaces/obra";

interface ProjectsProps{
    data?: Obra[];
    info?: Info;
}

export default function OurProjects({data, info}: ProjectsProps){
    const [edificios, setEdificios] = useState<Obra[]>(data!);

    useEffect(() => {
        setEdificios(data!);
    }, []);

    const renderEdificios = () => edificios?.map((v, i) => <CardEdificio data={v} key={i} columns={'4'}></CardEdificio>)

    return (
        <section className={`${style.listingBlock} ${style.ourProjects}`}>
            <div className={style.headerBackground}></div>
            <div className="container">
                <div className={style.sectionTitle}>
                    <span>{info?.subtitle}</span>
                    <h2>{info?.title}</h2>
                </div>
                <div className="row">
                    {renderEdificios()}
                </div>
            </div> 
   
        </section>
    )
}