import { Info } from "../interfaces/info";
import firstImage from "../media/icons/servicios/architect_blue.png";
import secondImage from "../media/icons/servicios/pay_blue.png";
import thirdImage from "../media/icons/servicios/home_blue.png";

export const MockServicios: Info[] = 
[
    {
        id: 1,
        title: 'Estudios de arquitectura',
        subtitle: '',
        text: `Si buscás un equipo de confianza para llevar adelante tus proyectos, estamos aquí para garantizar que cada detalle de tu visión se materialice con excelencia.`,
        imagen: firstImage
    },
    {
        id: 2,
        title: 'Inversores',
        subtitle: '',
        text: `Nuestro compromiso con la calidad y la rentabilidad asegura que los proyectos alcancen su máximo potencial. Te brindamos resultados que superan expectativas.`,
        imagen: secondImage
    },
    {
        id: 3,
        title: 'Tu hogar',
        subtitle: '',
        text: `Somos tu socio de construcción. Sea que sueñes con la construcción de tu hogar ideal o con un espacio único, nuestro equipo se dedica a hacer que tu visión se convierta en una realidad.`,
        imagen: thirdImage
    }
]