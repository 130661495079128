import { useEffect, useState } from "react";
import { ArchivoImagen } from "../../../interfaces/archivoImagen";
import style from "./style.module.css";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { Obra } from "../../../interfaces/obra";
import { useForm } from "react-hook-form";
import { FaTrashCan } from "react-icons/fa6";

interface BannerProps{
    data?: Obra;
    guardarBanner: any;  
}

export default function Banner({data, guardarBanner}: BannerProps){
    const [imagen, setImagen] = useState<any>();

    const { register, formState: {errors}, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            nombre: data?.nombre || '',
            imagen: imagen || imgDefault
        }
    });
    
    useEffect(() => {
        let imagenBanner = data?.imagenes?.find(o => o.seccionId == 2);
        setImagen(imagenBanner?.url);
        setValue("imagen", imagenBanner?.url);
    }, []);

    async function handleChange(e: any) {
        const target= e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if(file){
            setImagen(URL.createObjectURL(e.target.files[0]))
        }
    }

    const removeImg = () => {
        setValue("imagen", undefined);
        setImagen(undefined);
    }

    const guardar = async (formData: any) => {
        await guardarBanner(formData);
    }

    return (
        <div className={style.banner}>
            <img src={imagen || imgDefault} alt="banner" className={style.imgBanner} />
            <form onSubmit={handleSubmit(guardar)}>
                <label htmlFor="nombre">Nombre
                    <input type="text" {...register("nombre", {required: true})} id="nombre" placeholder="* Ingrese nombre" 
                        className={`form-control border border-primary ${style.formControlNombre}`} />
                    {errors.nombre?.type === 'required' && <p className="text-danger"> El campo nombre es requerido</p>}     
                    <div className="form-group d-flex gap-2">
                        <label className="form-label mt-4"></label>
                        <input className="form-control bg-dark text-light border border-primary" type="file" 
                            id="formFile" {...register("imagen")} name="imagen" onChange={handleChange} accept="image/*"/>
                        <button type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                    </div> 
                    <div className="col-md-12 text-center">
                        <button type="submit" id="bannerSubmit" className={`${style.boton}`} hidden>Guardar</button>
                    </div>          
                </label> 
            </form>           
        </div>
    )
}