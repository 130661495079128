import style from "./style.module.css";
import firstIcon from "../../../media/icons/counter/worker.png";
import secondIcon from "../../../media/icons/counter/architect.png";
import thirdIcon from "../../../media/icons/counter/entrega.png";
import fourthIcon from "../../../media/icons/counter/trayectoria.png";
import CountUp from 'react-countup';
import { GetParametroByClave } from "../../../services/parametroService";
import { useEffect, useState } from "react";

export default function Counters(){
    const [metrosConstruidos, setMetrosConstruidos] = useState<string>('60000');
    const [edificiosConstruidos, setEdificiosConstruidos] = useState<string>('4450');
    const [departamentosEntregados, setDepartamentosEntregados] = useState<string>('28');
    const [aniosTrayectoria, setAniosTrayectoria] = useState<string>('30');

    useEffect(() => {
        getCantidades();
    }, []);

    const getCantidades = async () => {
        let _metrosConstruidos = await GetParametroByClave('MetrosConstruidos');
        let _departamentosEntregados = await GetParametroByClave('DepartamentosEntregados');
        let _edificiosConstruidos = await GetParametroByClave('EdificiosConstruidos');
        let _aniosTrayectoria = await GetParametroByClave('AñosDeTrayectoria');
        if (_metrosConstruidos)setMetrosConstruidos(_metrosConstruidos?.valor || '60000');
        if (_departamentosEntregados)setEdificiosConstruidos(_departamentosEntregados?.valor || '4450');
        if (_edificiosConstruidos)setDepartamentosEntregados(_edificiosConstruidos?.valor || '28');
        if (_aniosTrayectoria)setAniosTrayectoria(_aniosTrayectoria?.valor || '30');
    }

    return (
        <section className={style.counters}>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <div className={style.counterInner}>
                            <img src={firstIcon} alt="Icon"/>
                                <h6 className={`${style.counter} ${style.editable}`} data-id="2405" data-clave="cantidad-proyectos">
                                    <CountUp className={`${style.counter}`} end={Number(metrosConstruidos)} duration={3}></CountUp>
                                </h6>
                                <span className={style.editable} data-clave="slogan-proyectos" data-id="0">
                                    M2 CONSTRUIDOS
                                </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className={style.counterInner}>
                            <img src={thirdIcon} alt="Icon"/>
                                <h6 className={`${style.counter} ${style.editable}`} data-id="2433" data-clave="cantidad-servicios">
                                    <CountUp className={`${style.counter}`} end={Number(edificiosConstruidos)} duration={3}></CountUp>
                                </h6>
                                <span className={style.editable} data-clave="slogan-servicios" data-id="0">
                                    DEPARTAMENTOS ENTREGADOS
                                </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className={style.counterInner}>
                            <img src={secondIcon} alt="Icon"/>
                                <h6 className={`${style.counter} ${style.editable}`} data-id="0" data-clave="cantidad-miembros">
                                    <CountUp className={`${style.counter}`} end={Number(departamentosEntregados)} duration={3}></CountUp>
                                </h6>
                                <span className={style.editable} data-clave="slogan-miembros" data-id="0">
                                    EDIFICIOS CONSTRUIDOS
                                </span>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className={style.counterInner}>
                            <img src={fourthIcon} alt="Icon"/>
                                <h6 className={`${style.counter} ${style.editable}`} data-id="2432" data-clave="cantidad-clientes">
                                    <CountUp className={`${style.counter}`} end={Number(aniosTrayectoria)} duration={3}></CountUp>
                                </h6>
                                <span className={style.editable} data-clave="slogan-clientes" data-id="0">
                                    AÑOS DE TRAYECTORIA
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}