import { enviroment } from "../interfaces/enviroment";

const BASE_URL: string = enviroment.urlBase() + "api/FTP";

export const ftpDownload = async (filePath: string): Promise<any> => {
    const url = `${BASE_URL}`;
   
    let result;
    const response = await fetch(`${BASE_URL}/${filePath}`, {
        method: "GET",
        headers:{
            //"Authorization": `Bearer ${JwtToken}`
        },
    })
    //console.log(response)
    if (!response.ok){
        throw new Error(response.statusText);        
    }
    console.log(response)
    result = await response.json();
    //console.log(result)
    if(response.ok && response.status == 200){
        return result;
    }
}

export const uploadFileFtp = async (data: FormData): Promise<any> => {
    const url = `${BASE_URL}`;
   
    let result;
    const response = await fetch(`${BASE_URL}`, {
        method: "POST",
        headers:{
            //"Content-Type": "multipart/form-data",
            //"Content-Type": "multipart/form-data; boundary=<calculated when request is sent>"
            //"Authorization": `Bearer ${JwtToken}`
        },
        body: data
    })
    
    if (!response.ok){
        throw new Error(response.statusText);        
    }

    result = await response.json();

    if(response.ok && response.status == 201){
        return result;
    }
    result = null;

}