import { enviroment } from "../interfaces/enviroment";
import { InfoDestacada } from "../interfaces/infoSignificante";
import { MockInfo } from "../mocks/info";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";

const BASE_URL: string = enviroment.urlBase() + "api/InfoDestacada";
const ENV_DEMO: boolean = enviroment.demo();

export const GetAllInfoDestacadaByObra = async (): Promise<InfoDestacada[]> => {
    if (ENV_DEMO){
        return MockInfo;
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        const data: InfoDestacada[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const InsertInfoDestacada = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
    
        result = await response.json();
        if(response.ok && response.status == 201){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const UpdateInfoDestacada = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }
        result = await response.json();
        if(response.ok && response.status == 200){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const DeleteInfoDestacada = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
    
        //result = await response.json();
        if(response.ok && response.status == 201){
            return response;
        }        
    } catch (error) {
        return undefined;
    }
}