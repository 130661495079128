
import style from "./style.module.css";
import logo from "../../media/logo.svg";
import emailIcon from "../../media/icons/email-us.png";
import phoneIcon from "../../media/icons/call-us.png";
import { FaWhatsapp } from "react-icons/fa6";


export default function Footer(){
    return (
        <footer className={style.footer}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12">                       
                        <div className={style.socials}>
                            <span>Nuestras redes:</span>
                            <ul className="d-flex justify-content-center d-flex justify-content-center container">
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank"><svg className="svg-inline--fa fa-facebook-f fa-w-9" aria-hidden="true" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512" data-fa-i2svg=""><path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path></svg></a>
                                </li>
                                {/* <li>
                                    <a href="https://www.youtube.com/"><svg className="svg-inline--fa fa-youtube fa-w-18" aria-hidden="true" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
                                </li> */}
                                <li>
                                    <a href="https://www.instagram.com/estudiomayocchi" target="_blank"><svg className="svg-inline--fa fa-instagram fa-w-14" aria-hidden="true" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center container">
                        <a href="#/inicio" className="container d-flex justify-content-center">
                            <img src={logo} className={style.imgLogo} alt="Logo"/>
                        </a>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-center text-start px-5">
                        <h6 className="d-none">Contacto</h6>
                        <div className={style.contactInfo}>
                            <ul>
                                <li>
                                    <img src={emailIcon} alt="Email Us"/>
                                        <div className={style.rightContent}>
                                            <a href="mailto:info@estudiomayocchi.com">info@estudiomayocchi.com</a>
                                        </div>
                                </li>
                                <li>
                                    <img src={phoneIcon} alt="Call Us"/>
                                        <div className={style.rightContent}>
                                            <a href="tel:2213146751">2213146751</a>
                                        </div>
                                </li>
                                <li className={style.whatsappContainer} hidden>
                                    <div className="d-flex justify-content-center py-2">
                                        <a href="https://wa.me/2213146751" target="_blank" className="p-1 rounded-circle d-flex justify-content-center align-center"><FaWhatsapp size={30}></FaWhatsapp></a>                                    
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}