import style from "./style.module.css";
import logo from "../../media/logo.png"
import logo2 from "../../media/logo.svg"
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";

interface HeaderProps{
    navItemActive:NavItemActive;
    opacity?: boolean;
}

export default function Header({navItemActive, opacity}: HeaderProps){
    const [styleTop, setStyleTop] = useState(85);
    const [clickedModal, setClickedModal] = useState(false);     
    const [bgColor, setBgColor] = useState(opacity? 'rgba(0,0,0,0.3)': 'rgba(0,0,0,0.0)'); 
    const [headerLogo, setHeaderLogo] = useState(logo);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        setTimeout(() => {
            onImageItemClick(document.querySelectorAll(".nav-item"));            
        }, 100);

        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        const header = document.getElementById("navHeader");

        if (window.scrollY > 0) {
            setStyleTop(0);
            setBgColor('var(--first-color)');
            setHeaderLogo(logo2);
        }
        else {
            setStyleTop(85);
            let colorBackground = opacity? 'rgba(0,0,0,0.3)': 'rgba(0,0,0,0.0)';
            setBgColor(colorBackground);
            setHeaderLogo(logo);
        }
    }

    const toggleModal = async () => {
        setClickedModal(!clickedModal)
    }

    function onImageItemClick(elements: any){
        elements.forEach((element: any) => {
            if(element.className.includes("active")){
                const children = element.children;
                children[0].style.backgroundColor = "var(--dark-color)"
                children[0].style.padding = "19px";                
                children[0].style.borderBottom = "6px solid var(--second-color)"
            }            
        });
    } 

    const returnTop = () => {
        window.scrollTo(0, 0);
    }
    
    return (
        <header className={style.header}>
            <nav className={`navbar navbar-expand-lg`} data-bs-theme="dark" id="navHeader" style={{backgroundColor : bgColor}}>
                <div className="container-fluid">
                    <a href="#/inicio" className={style.logo} onClick={returnTop}>
                        <img id="imgLogo" width={200} src={headerLogo} alt="Logo" className={style.imgLogo}/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarColor01">
                        <ul className={`navbar-nav me-auto px-1 ${style.navLinkContainer}`}>
                            {/* <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/administracion">ADMINISTRACIÓN</a>
                            </li> */}
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/contacto">CONTACTO</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/nosotros">NOSOTROS</a>
                            </li>   
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/servicios">SERVICIOS</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/inversiones">INVERSIONES</a>
                            </li>                      
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/obras">OBRAS</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${style.navLink}`} href="#/inicio">INICIO</a>
                            </li>                                                   
                        </ul>                        
                    </div>
                </div>
            </nav>
        </header>
    )
}