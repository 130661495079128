import { Info } from '../../../interfaces/info';
import style from './style.module.css'

export default function TopSection(){

    const infoSection: Info =  {
        id: 1,
        title: 'Nuestras Inversiones',
        subtitle: 'Una solución integral única en el mercado',
        text: `
        Tenemos una amplia cartera de inversiones para acompañarte en cada etapa del proceso. Desde la compra de tu departamento en pozo o metro cuadrado, hasta su comercialización o alquiler. 
        Tus intereses, nuestra prioridad.
        `,
        imagen: ''
    };

    return (
        <div>
            <div className={style.headerBackground}></div>
                <div className={style.topSection}>
                <div className={`${style.sectionTitle}`}>
                    <h2 className={` ${style.title}`}>
                        {infoSection?.title}
                    </h2>
                    <span className={` ${style.subtitle}`}>
                        {infoSection?.subtitle}
                    </span>
                </div>
                <div className={style.textContainer}>
                    <p className={` ${style.text}`}>
                        {infoSection?.text}
                    </p>
                </div>
            </div>
        </div>
    )   
}