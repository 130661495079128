import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Seccion } from "../../interfaces/seccion";

interface InfoProps{
    info?: Seccion;
}

export default function CardBanner({info}: InfoProps){
    const [infoBanner, setInfo] = useState(info);

    useEffect(() => {
        setInfo(info);
    }, []);

    return(
        <div className={`item ${style.item}`} style={{backgroundImage: `url(${infoBanner?.imagenUrl})`, width: "100%"}}>
            <div className={style.bannerCaption}>
                <h2>{infoBanner?.titulo}</h2>                                   
                <h3>{infoBanner?.descripcion}</h3>
            </div>                  
        </div>
    )
}