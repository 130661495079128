import { useEffect, useState } from "react";
import { ArchivoImagen } from "../../../interfaces/archivoImagen";
import style from "./style.module.css";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { Obra } from "../../../interfaces/obra";

interface BannerProps{
    data?: Obra;    
}

export default function Banner({data}: BannerProps){
    const [bannerImage, setBannerImage] = useState<ArchivoImagen | undefined>();

    useEffect(() => {
        setBannerImage(data?.imagenes?.find(o => o.seccionId == 2));
    }, []);

    const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {        
        event.currentTarget.src = imgDefault;
    };

    return (
        <div className={style.banner}>
            <img src={bannerImage?.url || imgDefault} alt="banner" className={style.imgBanner}
        onError={imageOnLoadHandler} style={{backgroundImage: `url(${bannerImage?.url})`, backgroundSize: "cover"}}/>
            <h2>{data?.nombre}</h2>
        </div>
    )
}