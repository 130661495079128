import Consulta from '../../../components/consulta'
import style from './style.module.css'
import imagen from "../../../media/img/contacto/contacto.webp";
import imgDefault from "../../../media/img/bannerEmpty.jpg";

export default function TopSection(){

    return (
        <div>
            <div className={style.headerBackground}></div>
            <div className={style.topSection}>
                <div className={`${style.sectionTitle}`}>
                    
                </div>
                <div className="row d-flex flex-wrap pb-5">
                    <div className="col-lg-6 mb-4">
                        <h2 className={` ${style.title}`}>
                            Nuestro equipo te espera para asesorarte
                        </h2>
                        <Consulta data={''}></Consulta>
                    </div>
                    <div className={`col-lg-6`}>
                        <img src={imagen || imgDefault} alt="Info" height="100%" className={style.imagen} />
                    </div>                    
                </div>
            </div>
        </div>
    )   
}