import Copyright from "../../components/copyright";
import Footer from "../../components/footer";
import Header from "../../components/header";
import ReturnTop from "../../components/return-top";
import Consulta from "../../components/consulta";
import MapFooter from "../../components/map-footer";
import DetailSection from "./detail-section";
import Banner from "./banner";
import Departments from "./departments";
import Amenities from "./amenities";
import { NavItemActive } from "../../interfaces/nav-item";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Obra } from "../../interfaces/obra";
import { GetObraById } from "../../services/obraService";
import WhatsappButton from "../../components/whatsapp-button";

export default function Propiedad(){
    const [styleTop, setStyleTop] = useState(false);
    const [edificio, setEdificio] = useState<Obra>();
    const [message, setMessage] = useState('');

    const { search } = useLocation();
    const id = new URLSearchParams(search).get("id");  

    useEffect(() => {        
        fetchEdificios();
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }        
    }, []);

    const fetchEdificios = async () => {
        let propiedad = id? await GetObraById(Number(id) || undefined): {};
        setEdificio(propiedad);
        setMessage(`Hola, quiero recibir más información por el emprendimiento ${propiedad?.nombre}. ¡Muchas gracias!`);
    }

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }

    const navItemActive = NavItemActive.Edificios;
    return (
        <div>
            <Header navItemActive={navItemActive} opacity={true}></Header>
            {edificio? <Banner data={edificio!}></Banner>: ''}            
            {edificio? <DetailSection data={edificio!}></DetailSection>: ''}
            {/* {message?<div id="consulta"><Consulta data={message}></Consulta></div>:''} */}
            {edificio? <Departments data={edificio!}></Departments>: ''}
            {edificio? <Amenities data={edificio!}></Amenities>: ''}
            {edificio? <MapFooter latitud={edificio?.latitud} longitud={edificio?.longitud}></MapFooter>: ''}
            <Footer></Footer>
            <Copyright></Copyright>
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}
