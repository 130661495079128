import style from "./style.module.css";
import { Info } from "../../interfaces/info";
import { useEffect, useState } from "react";
import MyModalContacto from "../modal";

interface InfoProps{
    data?: Info;
    contacto?: boolean;
    mensaje?: string;
}

export default function CardServicio({data, contacto, mensaje}: InfoProps){
    const [infoServicio, setInfo] = useState(data);
    const [message, setMessage] = useState(`Hola, quiero recibir más información sobre ${data?.title}. ¡Muchas gracias!`);
    
    useEffect(() => {
        setInfo(data);
        setMessage(`Hola, quiero recibir más información sobre ${data?.title}. ¡Muchas gracias!`);
    }, []);

    return(
        <div className="col-12 col-lg-4 d-flex justify-content-center mb-3 align-items-center">
            <div className={`d-flex flex-column justify-content-between align-items-center ${style.item}`}>
                <div className="container d-flex flex-column justify-content-center align-items-center">  
                    <img src={infoServicio?.imagen} alt="Logo" className={style.imagenServicio} />
                    <h3 className={style.title}>
                        {infoServicio?.title}
                    </h3>                
                </div>
                <p className={style.editable}>
                    {infoServicio?.text} 
                </p>
                {contacto?<div className="container d-flex justify-content-center align-items-end mt-4">
                    <MyModalContacto show={true} mensaje={message}></MyModalContacto>
                </div>: ''}
            </div>
        </div>
    )
}