import style from "./style.module.css";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { ArchivoImagen } from "../../../interfaces/archivoImagen";
import CardFotoCarousel from "../../../components/cardFotoCarousel";

interface ProjectsProps{
    data?: ArchivoImagen[];
    idSeccion?: number;
}
export default function DetailProjects({data, idSeccion}: ProjectsProps){
    const [imagenes, setImagenes] = useState<ArchivoImagen[]>();
    const [seccion, setSeccion] = useState<number>(idSeccion!);
    
    const fetchImagenes = async () => {
        let lista = await data?.filter(o => o.seccionId == idSeccion);
        setSeccion(idSeccion!);
        setImagenes(lista);
    }

    useEffect(() => {        
        fetchImagenes();
    }, []);

    const options = {
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        navContainerClass: `owl-nav ${style.customNav}`,
        navText: [`<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
        </svg>`,
        `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
        </svg>`],
        navClass: [`${style.buttonNav}`,`${style.buttonNav}`],
        smartSpeed: 1000,
        responsive:{ 0 : { items:1 }, }
    };

    const renderFotos = () => imagenes?.map((v, i) => <CardFotoCarousel data={v} key={i}></CardFotoCarousel>)

    return (
        <section className={style.ourProjects}>
            <OwlCarousel className='owl-theme' loop {...options}>                
                {imagenes? renderFotos(): ''}           
            </OwlCarousel>  
        </section>
    )
}