import CardNosotros from '../../../components/cardNosotros';
import { Info } from '../../../interfaces/info';
import { MockValores } from '../../../mocks/valores';
import style from './style.module.css'

export default function ValoresSection(){
    
    const infoList: Info[] =  MockValores;

    const infoSection: Info =  {
        id: 1,
        title: 'Una trayectoria basada en valores',
        subtitle: '',
        text: `Cada ladrillo que colocamos, cada diseño que creamos y cada proyecto que entregamos está impregnado de nuestros valores fundamentales:`,
        imagen: ''
    };

    const renderServicios = () => infoList?.map((v, i) => <CardNosotros data={v} key={i}></CardNosotros>)
    return (
        <section className={style.section}>
            <div className="container">
                <div className={`text-center my-5 ${style.sectionTitle}`}>
                    <h2 className={` ${style.title}`}>
                        {infoSection?.title}
                    </h2>
                    <span className={` ${style.subtitle}`}>
                        {infoSection?.text}
                    </span>
                </div>
                <div className="row">
                    {infoList? renderServicios(): ''}
                </div>
            </div>
        </section>
    )
}