import { useEffect, useState } from "react";
import style from "./style.module.css";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { Info } from "../../../interfaces/info";

interface BannerProps{
    data?: Info;    
}

export default function Banner({data}: BannerProps){
    const [bannerImage, setBannerImage] = useState(data?.imagen);

    useEffect(() => {
        setBannerImage(data?.imagen);
    }, []);

    return (
        <div className={style.section}>
            <div className={style.banner}>
                <img src={bannerImage || imgDefault} alt="banner" className={style.imgBanner} />                
            </div>
            <div className="container d-flex flex-column justify-content-center text-center my-4">
                <h2>{data?.title}</h2>
                <p className={style.text}>{data?.text}</p>
            </div>
        </div>
    )
}