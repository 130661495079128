import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import { Info } from "../../interfaces/info";
import Header from "../../components/header";
import Copyright from "../../components/copyright";
import Footer from "../../components/footer";
import ReturnTop from "../../components/return-top";
import Consulta from "../../components/consulta";
import SectionTextLeft from "../../components/sectionTextLeft";
import imagen from "../../media/img/homepage/servicios.webp";
import Projects from "./build-projects";
import Counters from "./counters";
import OurSolutions from "./inversiones-home";
import MapFooter from "../../components/map-footer";
import TopBanner from "./top-banner";
import { Obra } from "../../interfaces/obra";
import { GetAllObras } from "../../services/obraService";
import SpinnerLoading from "../../components/spinner";
import WhatsappButton from "../../components/whatsapp-button";

export default function Home(){    
    const [loading, setLoading] = useState(false);
    const [styleTop, setStyleTop] = useState(false);
    const [scrollCounter, setScrollCounter] = useState(false);
    const [edificios, setEdificios] = useState<Obra[]>();

    const fetchEdificios = async () => {
        setLoading(true);
        let lista: Obra[] = await GetAllObras();
        setEdificios(lista);
        setLoading(false);
    }         
    
    useEffect(() => {
        fetchEdificios();
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
        if (window.scrollY >= 1400) {
            setScrollCounter(true);
        }
    }

    const info: Info =  {
        id: 1,
        title: 'Damos vida a tus ideas',
        subtitle: 'Proyecto, dirección y ejecución de obras de arquitectura',
        text: `
        Si tenés una idea la ejecutamos, y sino, la creamos juntos. 
        Sabemos que cada proyecto es un sueño, por eso diseñamos y construimos lugares donde sueñes despertarte.
        `,
        imagen: imagen
    };

    const navItemActive = NavItemActive.Inicio;
    return (
        <div>
            <Header navItemActive={navItemActive} opacity={true}></Header>
            <TopBanner></TopBanner>
            <OurSolutions></OurSolutions>
            {edificios ? <Projects data={edificios.slice(0, 2)}></Projects> : ''}
            <SectionTextLeft info={info} contacto={true} buttonLink="obras"></SectionTextLeft>
            {scrollCounter?<Counters></Counters>:''}
            <MapFooter></MapFooter>  
            <Consulta data={''}></Consulta>    
            <br />
            <Footer></Footer>
            <Copyright></Copyright>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
            {/* {styleTop ? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}