import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Seccion } from "../../interfaces/seccion";
import imgDefault from "../../media/img/bannerEmpty.jpg";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import MyModalConfirm from "../modalConfirm";
import MyModalInfo from "../modalInfo";
import SpinnerLoading from "../spinner";
import { DeleteSection } from "../../services/sectionService";

interface InfoProps{
    info?: Seccion;
    editBanner?: any;
    tipoSeccion?: string;
}

export default function CardBannerAdmin({info, editBanner, tipoSeccion}: InfoProps){
    const [infoBanner, setInfo] = useState(info);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setInfo(info);
    }, []);
    
    const deleteSeccion = () => {
        let btnConfirmar = document.getElementById(`btnEliminar${tipoSeccion}_${infoBanner?.id}`);
        btnConfirmar?.click();
    }

    const confirmarEliminar = async () => {
        //console.log("eliminado", infoBanner)
        setLoading(true);
        await DeleteSection(infoBanner?.id!)
        setLoading(false)
        let btnConfirmar = document.getElementById(`btnModalInfo${tipoSeccion}`);
        btnConfirmar?.click();
    }
           
    const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {        
        event.currentTarget.src = "https://placehold.co/400x400?text=...";
    };

    return(
        <div className={`item ${style.item}`}>
            <div className={style.bannerCaption}>
                <img src={infoBanner?.imagenUrl || imgDefault} alt="banner" className="w-100" onError={imageOnLoadHandler}/>
                {tipoSeccion == 'Banner'?<h4>{infoBanner?.titulo}</h4>:''}
                {tipoSeccion == 'Banner'?<p>{infoBanner?.descripcion}</p>:''}
                <div className="d-flex justify-content-around gap-3">
                    <button className="bg-danger" onClick={deleteSeccion}><FaTrashCan color="white" size={20}></FaTrashCan></button>
                    <button className="bg-success" onClick={() => editBanner(info)}><FaPencil color="white" size={20}></FaPencil></button>
                </div>
            </div>
            <MyModalConfirm idButton={`btnEliminar${tipoSeccion}_${infoBanner?.id}`} titulo={infoBanner?.titulo} mensaje={`¿Desea eliminar el ${tipoSeccion}?`} callback={confirmarEliminar} show={true}></MyModalConfirm>
            <MyModalInfo idButton={`btnModalInfo${tipoSeccion}`} titulo={"Confirmacion"} mensaje={`${tipoSeccion} eliminado con éxito`} headerColor="success" show={true}></MyModalInfo>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </div>
    )
}