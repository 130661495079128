import { useEffect, useState } from 'react';
import { Etapa } from '../../interfaces/etapa'
import style from './style.module.css'
import { colors } from 'react-select/dist/declarations/src/theme';
import { FaCaretRight, FaRegCircleRight } from 'react-icons/fa6';

interface CardEtapa{
    data: Etapa;
    etapaSuperada: boolean;    
    ultimaEtapa: boolean; 
    onClickEtapa?: any;   
}

export default function CardEtapa({data, etapaSuperada, ultimaEtapa, onClickEtapa}: CardEtapa){
    const [etapa, setEtapa] = useState<Etapa>(data);
    const [background, setBackGround] = useState('');

    useEffect(() => {
        setEtapa(data);
        //etapaSuperada? setBackGround('rgba(0,0,0,0.2)'): setBackGround('linear-gradient(to top, #42A341, #B8E2A3)');
        etapaSuperada? setBackGround('rgba(0,0,0,0.2)'): setBackGround('var(--first-color)');
    }, []);  

    const clickEtapa = (event: any) => {        
        onClickEtapa(event.currentTarget.id);
    }
    
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className={style.divStatus}>
                <div id={etapa?.id?.toString()} className={style.iconProgressContainer} style={{background: background}} onClick={clickEtapa}>
                    <img src={etapa?.imagen} alt="" />
                </div>
                <label className={style.labelEtapa}>{etapa?.descripcion}</label>
            </div>
            {ultimaEtapa?<FaRegCircleRight className={style.arrowRight}></FaRegCircleRight>:''}
        </div>
    )
}