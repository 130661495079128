import Copyright from "../../components/copyright";
import Footer from "../../components/footer";
import Header from "../../components/header";
import ReturnTop from "../../components/return-top";
import MapFooter from "./map-footer";
import DetailSection from "./detail-section";
import Banner from "./banner";
import Departments from "./departments";
import Amenities from "./amenities";
import { NavItemActive } from "../../interfaces/nav-item";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router";
import { Obra } from "../../interfaces/obra";
import { ArchivoImagen } from "../../interfaces/archivoImagen";
import { InfoDestacada } from "../../interfaces/infoSignificante";
import { AmenitiesObras } from "../../interfaces/infoServicio";
import { uploadFile } from "../../services/firebase/config";
import { DeleteImagenObra, GetObraById, InsertObra, InsertImagenObra, UpdateObra } from '../../services/obraService'
import MyModalConfirm from "../../components/modalConfirm";
import SpinnerLoading from "../../components/spinner";
import MyModalInfo from "../../components/modalInfo";
import { DeleteAmenity, InsertAmenity } from "../../services/amenityService";
import { DeleteInfoDestacada, InsertInfoDestacada, UpdateInfoDestacada } from "../../services/infoDestacadaService";
import { FaChevronLeft } from "react-icons/fa6";
import { UploadImgbb } from "../../services/imgbbService";
import { uploadFileFtp } from "../../services/ftpService";
import { DeleteArchivosPDF } from "../../services/archivoPdfService";

export default function PropiedadABM(){
    const [loading, setLoading] = useState(false);
    const [styleTop, setStyleTop] = useState(false);
    const [edificio, setEdificio] = useState<Obra>();
    const [message, setMessage] = useState('');
    const [latitud, setLatitud] = useState<string>();
    const [longitud, setLongitud] = useState<string>();


    let imagenesObra: ArchivoImagen[];
    let infoObra: InfoDestacada[];
    let infoServicioObra: AmenitiesObras[];    
    let imagenesEliminar: number[] = [];    

    //Children data
    let detailData: any;
    let bannerData: any;
    let departmentsData: any;
    let amenitiesData: any;
    let propiedad: any;

    //Children submits
    let btnBannerSubmit = document.getElementById('bannerSubmit');
    let btnSubmit = document.getElementById('detailSectionSubmit');
    let btnDepartmentsSubmit = document.getElementById('departmentsSubmit');
    let btnAmenitiesSubmit = document.getElementById('amenitiesSubmit');
        
    
    //GET URL parameters 
    const { search } = useLocation();
    const id = new URLSearchParams(search).get("id");  
    
    useEffect(() =>  {        
        fetchEdificios();
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const fetchEdificios = async () => {
        propiedad = id? await GetObraById(Number(id) || undefined): {};
        
        setEdificio(propiedad);
        let mensaje = `Hola, quiero recibir más información por el emprendimiento ${propiedad?.nombre || ''}. ¡Muchas gracias!`;        
        
        setMessage(mensaje);
    }

    let handleClick = () => { };
    
    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }

    const guardarObra = async () => { 
        await btnBannerSubmit?.click();
        await btnSubmit?.click();
        await btnDepartmentsSubmit?.click();
        await btnAmenitiesSubmit?.click();            

        imagenesObra = [];
        infoObra = [];
        infoServicioObra = [];        

        setTimeout(() => {     
            if(detailData && bannerData && departmentsData && amenitiesData){
                let btnConfirmar = document.getElementById("btnConfirmarObra");
                btnConfirmar?.click();
            }
            // detailData = undefined;
            // bannerData = undefined;
            // departmentsData = undefined;
            // amenitiesData = undefined;
        }, 1000);
    }

    const getUbicacion = (lat: number, long: number) => {
        setLatitud(lat.toString());
        setLongitud(long.toString());
    }
    const getBanner = async (formData: any) => {
        bannerData = await formData;
    }
    const getDetail = async (formData: any) => {
        detailData = await formData;
    }
    const getDepartments = async (formData: any) => {
        departmentsData = await formData;
    }
    const getAmenities = async (formData: any) => {
        amenitiesData = await formData;        
    }

    const confirmar = async () => { 
        //Imagenes  
        const imgDefault = '/static/media/bannerEmpty.a125429118a2c2899a77.jpg'
        let imagenBanner: ArchivoImagen = { url: bannerData?.imagen, descripcion: 'banner', obraId: edificio?.id || 0, seccionId: 2 };
        if (imagenBanner?.url && imagenBanner?.url != imgDefault) { imagenesObra.push(imagenBanner) };
        
        let imagenPrincipal: ArchivoImagen = { url: detailData?.imagenPrincipal, descripcion: 'imagenPrincipal', obraId: edificio?.id || 0, seccionId: 1 };
        if (imagenPrincipal?.url && imagenPrincipal?.url != imgDefault) { imagenesObra.push(imagenPrincipal) };

        if(departmentsData?.imagen1 && departmentsData?.imagen1 != imgDefault) { imagenesObra.push({ url: departmentsData?.imagen1, descripcion: 'departamentos_1', obraId: edificio?.id || 0, seccionId: 3 }) };
        if(departmentsData?.imagen2 && departmentsData?.imagen2 != imgDefault) { imagenesObra.push({ url: departmentsData?.imagen2, descripcion: 'departamentos_2', obraId: edificio?.id || 0, seccionId: 3 }) };
        if(departmentsData?.imagen3 && departmentsData?.imagen3 != imgDefault) { imagenesObra.push({ url: departmentsData?.imagen3, descripcion: 'departamentos_3', obraId: edificio?.id || 0, seccionId: 3 }) };
        if(departmentsData?.imagen4 && departmentsData?.imagen4 != imgDefault) { imagenesObra.push({ url: departmentsData?.imagen4, descripcion: 'departamentos_4', obraId: edificio?.id || 0, seccionId: 3 }) };       

        if(amenitiesData?.imagen1 && amenitiesData?.imagen1 != imgDefault) { imagenesObra.push({ url: amenitiesData?.imagen1, descripcion: 'amenity_1', obraId: edificio?.id || 0, seccionId: 4 }) };
        if(amenitiesData?.imagen2 && amenitiesData?.imagen2 != imgDefault) { imagenesObra.push({ url: amenitiesData?.imagen2, descripcion: 'amenity_2', obraId: edificio?.id || 0, seccionId: 4 }) };
        if(amenitiesData?.imagen3 && amenitiesData?.imagen3 != imgDefault) { imagenesObra.push({ url: amenitiesData?.imagen3, descripcion: 'amenity_3', obraId: edificio?.id || 0, seccionId: 4 }) };
        if(amenitiesData?.imagen4 && amenitiesData?.imagen4 != imgDefault) { imagenesObra.push({ url: amenitiesData?.imagen4, descripcion: 'amenity_4', obraId: edificio?.id || 0, seccionId: 4 }) };

        //InfoDestacada
        if(detailData?.Info1) { infoObra.push({ descripcion: detailData?.Info1, obraId: edificio?.id || 0}) };
        if(detailData?.Info2) { infoObra.push({ descripcion: detailData?.Info2, obraId: edificio?.id || 0}) };
        if(detailData?.Info3) { infoObra.push({ descripcion: detailData?.Info3, obraId: edificio?.id || 0}) };
        if(detailData?.Info4) { infoObra.push({ descripcion: detailData?.Info4, obraId: edificio?.id || 0}) };
        
        //AmenitiesObras
        if(amenitiesData?.Info1) { infoServicioObra.push({ descripcion: amenitiesData?.Info1, obraId: edificio?.id || 0}) };
        if(amenitiesData?.Info2) { infoServicioObra.push({ descripcion: amenitiesData?.Info2, obraId: edificio?.id || 0}) };
        if(amenitiesData?.Info3) { infoServicioObra.push({ descripcion: amenitiesData?.Info3, obraId: edificio?.id || 0}) };
        if(amenitiesData?.Info4) { infoServicioObra.push({ descripcion: amenitiesData.Info4, obraId: edificio?.id || 0}) };

        propiedad = {
            ...edificio,
            nombre: bannerData?.nombre || edificio?.nombre,
            direccion: detailData?.direccion || edificio?.direccion,
            ciudad: detailData?.ciudad || edificio?.ciudad,
            fechadeCreación: detailData?.fechadeCreación || edificio?.fechadeCreación,
            latitud: latitud || edificio?.latitud,
            longitud: longitud || edificio?.longitud,
            etapaObraId: detailData?.etapa || edificio?.etapaObraId,
            descripcion: detailData?.descripcion || edificio?.descripcion,
            descripcionDepartamentos: departmentsData?.descripcionDepartamentos || edificio?.descripcionDepartamentos,
            descripcionAmenities: amenitiesData?.descripcionAmenities || edificio?.descripcionAmenities,
            tituloAmenities: amenitiesData?.tituloAmenities || edificio?.tituloAmenities,
            archivoPDF: detailData?.archivoPDF || edificio?.archivoPDF,
            imagenes: imagenesObra || edificio?.imagenes,
            infoDestacada: infoObra || edificio?.infoDestacada,
            amenitiesObras: infoServicioObra || edificio?.amenitiesObras
        };

        
        //console.log(detailData)
        //console.log(propiedad)

        if (propiedad?.id > 0){
            propiedad = {
                ...propiedad,
                archivoPDF: undefined,
                imagenes: [],
                infoDestacada: [],
                amenitiesObras: [],
            }
            imagenesEliminar = [];
            await validarArchivoPDF();
            await validarImagenes();
            await validarInfoDestacada();
            await validarAmenitiesObras();
            
            let result = await UpdateObra(propiedad);
            confirmarModal(result);           
        }
        else{
            await validarArchivoPDF();
            await insertarImagenes();
             
            propiedad = {
                ...propiedad,
                imagenes: imagenesObra,
                latitud: latitud,
                longitud: longitud
            }
            //console.log(propiedad);
            let result = await InsertObra(propiedad);
            confirmarModal(result);        
        }
    }

    const insertarImagenes = async () => {   
        for (let index = 0; index < imagenesObra?.length; index++) {     
            let seccionId = imagenesObra?.[index].seccionId;  
            let lastModified = imagenesObra?.[index]?.url?.[0]?.lastModified;
            let nombreArchivo = imagenesObra?.[index]?.url?.[0]?.name;
            const _formData = new FormData();
            _formData.append('image', imagenesObra?.[index]?.url?.[0]);
            _formData.append('name', `${propiedad?.nombre}/${seccionId}_${imagenesObra?.[index].descripcion}_${seccionId!>2?lastModified:''}`);
            const result = await UploadImgbb(_formData);
            // const result = await uploadFile(imagenesObra?.[index]?.url?.[0], 
            //     `estudiomayocchi/obras-imagenes/${propiedad?.nombre}/${seccionId}_${imagenesObra?.[index].descripcion}_${seccionId!>2?lastModified:''}`);
            imagenesObra[index].nombreDeArchivo = nombreArchivo;
            imagenesObra[index].url = result;
        }
    }

    
    const validarArchivoPDF = async () => {
        if ((!edificio?.archivoPDF && detailData?.archivoPDF) || edificio?.archivoPDF?.url != detailData?.archivoPDF?.url){
            const file = detailData?.archivoPDF?.[0];
            let lastModified = file?.lastModified;
            //console.log(file)
            if(file){
                try {
                    let obraId = propiedad?.id;
                    if (obraId > 0){          
                        await DeleteArchivosPDF([obraId]);
                    }
                    let fileName = `${propiedad?.nombre}_${lastModified}.pdf`;
                    const _formData = new FormData();
                    _formData.append('file', file);
                    _formData.append('fileName', fileName);
                    //const result = await uploadFileFtp(_formData);
                    const result = await uploadFile(file, `estudiomayocchi/obras-pdf/${propiedad?.nombre}_${lastModified}`);
                    propiedad = await {
                        ...propiedad
                        //,archivoPDF: {url: fileName, nombreArchivo: fileName, obraId: propiedad?.id}
                        ,archivoPDF: {url: result, nombreArchivo: fileName, obraId: propiedad?.id}
                    }
                } catch (error) {
                    console.log(error);
                }
            }            
        }
    }

    const validarImagenes = async () => {
        let imagenesExistentes = edificio?.imagenes?.map(o => o.url);
        let imagenesInsertar = [];

        for (let index = 0; index < imagenesObra?.length; index++) {
            let existe = imagenesExistentes?.includes(imagenesObra?.[index].url);
            let nombreArchivo = imagenesObra?.[index]?.url?.[0]?.name;

            if(!existe){
                let seccionId = imagenesObra?.[index].seccionId;  
                let lastModified = imagenesObra?.[index]?.url?.[0]?.lastModified;

                if (imagenesObra?.[index].url){
                    const _formData = new FormData();
                    _formData.append('image', imagenesObra?.[index]?.url?.[0]);
                    _formData.append('name', `${propiedad?.nombre}/${seccionId}_${imagenesObra?.[index].descripcion}_${seccionId!>2?lastModified:''}`);
                    const result = await UploadImgbb(_formData);
                    // const result = await uploadFile(imagenesObra?.[index]?.url?.[0], 
                    //     `estudiomayocchi/obras-imagenes/${propiedad?.nombre}/${seccionId}_${imagenesObra?.[index].descripcion}_${seccionId!>2?lastModified:''}`);
                    
                    imagenesObra[index].nombreDeArchivo = nombreArchivo;
                    imagenesObra[index].url = result;
                    imagenesInsertar.push(imagenesObra?.[index]);
                    if (seccionId! < 3){
                        imagenesEliminar.push(edificio?.imagenes?.find(o => o.seccionId === seccionId)?.id || 0);
                    }
                }          
            }
        }
        
        if (imagenesInsertar.length > 0) { await InsertImagenObra(imagenesInsertar) };
        await eliminarImagenSeccion(3);
        await eliminarImagenSeccion(4);
        if (imagenesEliminar.length > 0) { await DeleteImagenObra(imagenesEliminar) };
    }

    const eliminarImagenSeccion = async (seccionId: number) => {
        let imagenesExistentes = edificio?.imagenes?.filter(o => o.seccionId === seccionId).map(o => o.url);
        let imagenesNuevas = imagenesObra?.filter(o => o.seccionId === seccionId).map(o => o.url);

        for (let index = 0; index < imagenesExistentes?.length!; index++) {
            let existe = imagenesNuevas?.includes(imagenesExistentes?.[index]);
            if(!existe){
                let edificioEliminar = edificio?.imagenes?.find(o => o.url === imagenesExistentes?.[index] && o.seccionId === seccionId);
                imagenesEliminar.push(edificioEliminar?.id || 0);
            }
        }
        if (imagenesEliminar.length > 0) { await DeleteImagenObra(imagenesEliminar) };
    }

    const validarInfoDestacada = async () => {
        let infoExistentes = edificio?.infoDestacada?.map(o => o.id);
        if (infoExistentes && infoExistentes.length > 0) { await DeleteInfoDestacada(infoExistentes) };
        if (infoObra.length > 0) { await InsertInfoDestacada(infoObra) };

        // let infoNuevas = infoObra?.map(o => o.descripcion);
        // let infoInsertar = [];

        // for (let index = 0; index < infoNuevas?.length; index++) {
        //     let existe = infoExistentes?.includes(infoNuevas?.[index]);
            
        //     if(!existe){
        //         let infoUpdate = edificio?.infoDestacada?.find(o => o.descripcion === infoExistentes?.[index]);
        //         if (infoUpdate){
        //             infoUpdate.descripcion = infoNuevas?.[index];
        //             console.log(`actualizar infoDestacada`, index, infoUpdate);
        //             await UpdateInfoDestacada(infoUpdate);
        //         }
        //         else{
        //             await console.log("subir ", infoNuevas?.[index]);
        //             infoInsertar.push({descripcion: infoNuevas?.[index], obraId: edificio?.id});
        //         }
        //     }
        // }
        // if (infoInsertar. length > 0) { await InsertInfoDestacada(infoInsertar) };
    }

    const validarAmenitiesObras = async () => {
        let amenitiesExistentes = edificio?.amenitiesObras?.map(o => o.id);
        if (amenitiesExistentes && amenitiesExistentes.length > 0) { await DeleteAmenity(amenitiesExistentes) };
        if (infoServicioObra.length > 0) { await InsertAmenity(infoServicioObra) };
    }

    const guardarObraButton = () => {
        setLoading(true);
        confirmar(); 
    }

    function confirmarModal(result: any){
        if (result){
            let btnConfirmar = document.getElementById(`btnModalInfoPropiedad`);
            btnConfirmar?.click();
        }
        else{
            let btnConfirmar = document.getElementById(`btnModalError`);
            btnConfirmar?.click();
        }
        setLoading(false);
    }

    const navigate = useNavigate();
    const goBack = () => {
		navigate(-1);
	}

    const navItemActive = NavItemActive.Edificios;
    return (
        <div>
            <Header navItemActive={navItemActive} opacity={true}></Header>
            {edificio? <Banner data={edificio!} guardarBanner={getBanner}></Banner>: ''}            
            {edificio? <DetailSection data={edificio!} guardarDetail={getDetail} submit={ (submit: () => void) => { handleClick = submit; } }></DetailSection>: ''}
            {/* {message?<div id="consulta"><Consulta data={message}></Consulta></div>:''} */}
            {edificio? <Departments data={edificio!} guardarDepartments={getDepartments} ></Departments>: ''}
            {edificio? <Amenities data={edificio!} guardarAmenities={getAmenities} ></Amenities>: ''}
            {edificio? <MapFooter latitud={edificio?.latitud} longitud={edificio?.longitud} guardarUbicacion={getUbicacion}></MapFooter>: ''}

            <div className="d-flex container justify-content-center my-5 gap-2">                
                <button  className="btn btn-outline-info" onClick={goBack}><FaChevronLeft></FaChevronLeft></button>
                <button type="button" onClick={guardarObra}>Guardar</button>
            </div>

            <Footer></Footer>
            <Copyright></Copyright>
            <MyModalConfirm idButton={"btnConfirmarObra"} titulo={"Obra"} mensaje={"¿Desea guardar los cambios?"} callback={guardarObraButton} show={true}></MyModalConfirm>
            <MyModalInfo idButton="btnModalInfoPropiedad" titulo={"Confirmacion"} mensaje="Obra guardada con éxito" headerColor="success" show={true}></MyModalInfo>
            <MyModalInfo idButton="btnModalError" titulo={"Error"} mensaje="Ocurrió un error" headerColor="danger" show={true}></MyModalInfo>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
            {styleTop? <ReturnTop></ReturnTop> : ''}
        </div>
    )
}
