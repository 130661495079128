import style from './style.module.css'
import { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";

interface ModalProps{
    idButton?: string,
    titulo?: string,
    mensaje?: string,
    headerColor?: string,
    show?: boolean;
}

export default function MyModalInfo({idButton, titulo, mensaje, headerColor, show}: ModalProps){    
    const [showModal, setShow] = useState(false);
    const [title, setTitle] = useState(titulo);
    const [message, setMessage] = useState(mensaje);
    const [header, setHeader] = useState(headerColor);

    useEffect(() => {
        setTitle(title || '');
        setMessage(mensaje || '');
        setHeader(headerColor || '');
    }, []);
    
    const handleClose = () => {setShow(false); window.location.reload();}
    const handleShow = () => setShow(true);

    const confirmar = () => {
        handleClose();
    }

    return ( 
        <div>
            <button id={idButton} className={style.buttonContacto} onClick={handleShow} hidden>
            </button>
            <Modal show={showModal} onHide={handleClose}                
                centered
                >               
                <Modal.Header closeButton className={`${style.modalHeader}`} id="modalHeader" bsPrefix={`d-flex justify-content-between align-items-center p-4 bg-${header}`}>
                    <h2>{title}</h2>
                </Modal.Header>
                <Modal.Body className={style.modalBody}>
                    <p>{mensaje}</p>
                </Modal.Body>                
                <Modal.Footer className={`d-flex justify-content-center ${style.modalFooter}`}>
                    <Button variant="info" onClick={confirmar}>Aceptar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}