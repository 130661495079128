import { ArchivoImagen } from "../interfaces/archivoImagen";
import live1 from "../media/img/propiedades/live1.jpg";
import live_1 from "../media/img/propiedades/live/baby4.jpg";
import live_2 from "../media/img/propiedades/live/babylo3.jpg";
import live_3 from "../media/img/propiedades/live/babylon4.jpg";
import live_4 from "../media/img/propiedades/live/babylon5.jpg";
import live_5 from "../media/img/propiedades/live/babylon6.jpg";


export const MockImagenes: ArchivoImagen[] = 
[
    {id: 1, descripcion: 'La casa del vidrio', url: live1, obraId: 1, seccionId: 1 },
    {id: 2, descripcion: 'Banner', url: live_1, obraId: 1, seccionId: 2 },
    {id: 3, descripcion: 'Arttec', url: live_1, obraId: 1, seccionId: 3 },
    {id: 4, descripcion: 'Dun Dun', url: live_2, obraId: 1, seccionId: 3 },
    {id: 5, descripcion: 'Anacleto', url: live_3, obraId: 1, seccionId: 3 },
    {id: 6, descripcion: 'Yaneff Arquitectura', url: live_4, obraId: 1, seccionId: 3 },
    {id: 7, descripcion: 'Plomiplas', url: live_5, obraId: 1, seccionId: 4 },
    {id: 8, descripcion: 'Anacleto', url: live_3, obraId: 1, seccionId: 4 },
    {id: 9, descripcion: 'Yaneff Arquitectura', url: live_4, obraId: 1, seccionId: 4 },
    {id: 10, descripcion: 'Yaneff Arquitectura', url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/estudiomayocchi%2Fobras-imagenes%2F1%2F4_imagen_amenities_1384141200359?alt=media&token=d4262d09-f589-433f-a940-497992551832',
         obraId: 1, seccionId: 4 },
]