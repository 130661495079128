import style from './style.module.css'
import { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import Consulta from '../consulta';

interface ModalProps{
    mensaje?: string,
    show: boolean;
}

export default function MyModalContacto({mensaje, show}: ModalProps){    
    const [showModal, setShow] = useState(false);
    const [message, setMessage] = useState(mensaje);

    useEffect(() => {
        setMessage(mensaje || '');
    }, []);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return ( 
        <div>
            <button className={style.buttonContacto} onClick={handleShow}>
                CONTACTO
            </button>
            <Modal show={showModal} onHide={handleClose}
            size="lg" centered
            >               
            <Modal.Header closeButton style={{borderBottom: 0}} className={style.backgroundPrimaryHeader}></Modal.Header>
                <Modal.Body className={style.backgroundPrimary}>
                    <Consulta data={message!}></Consulta>
                </Modal.Body>                
            </Modal>
        </div>
    )
}