import { Info } from "../interfaces/info";
import firstImage from "../media/img/inversiones/inversionpozo.webp";
import secondImage from "../media/img/inversiones/inversionesm2 .webp";
import thirdImage from "../media/img/inversiones/inversiones003.webp";

export const MockInversiones: Info[] = 
[
    {
        id: 1,
        title: 'Consorcio al costo',
        subtitle: 'Maximizá tu ganancia',
        text: `El sistema de construcción al costo es ideal para inversores inmobiliarios, ya que el propietario del departamento paga, como su nombre lo indica, lo que cuesta su construcción sin ningún valor adyacente al mismo, como beneficios empresariales o cargas financieras. Los inversores adquieren una propiedad de excelente calidad, a un valor muy inferior del mercado para obras terminadas, obteniendose así, un margen de rentabilidad importante en una futura venta.
        El consorcio es la figura legal elegida en nuestros más de 30 años de actividad. ¿Por qué? Es el sistema que mejor respalda a nuestros clientes en todo sentido. La garantía legal es total, desde un principio, dado que se realiza la escrituración del terreno, en la parte indivisa que corresponda, a favor del propietario antes de comenzar la obra. Se genera una figura independiente con garantía absoluta sobre el bien adquirido y todo lo que construya sobre el mismo, puesto que no podrá ser sometido a ningún tipo de gravamen (hipoteca, etc), ni transferencia, salvo por decisión de los mismos propietarios.
        `,
        imagen: firstImage
    },
    {
        id: 2,
        title: 'Inversión por m2',
        subtitle: 'Tu departamento propio a metros de distancia',
        text: `
        Cada metro es un paso más cerca de tu departamento propio y un activo de revalorización dolarizado. 
        Ideal para dar los primeros pasos hacia tu propiedad y revalorizar tu fracción con el avance de la obra.
        `,
        imagen: secondImage
    },
    {
        id: 3,
        title: 'Alquiler temporario',
        subtitle: 'Convertimos tu departamento en un activo dolarizado',
        text: `Transformamos tu departamento en un activo de renta y revalorización dolarizada de manera de obtener el mayor margen de retorno en tu inversión. 
        
        Conocé nuestras unidades en alquiler temporario.
        `,
        imagen: thirdImage
    }
]