import { useEffect, useState } from 'react';
import { Obra } from '../../../interfaces/obra';
import style from './style.module.css';
import { FaFilePen, FaPencil, FaTrashCan } from 'react-icons/fa6';
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import MyModalConfirm from '../../../components/modalConfirm';
import MyModalInfo from '../../../components/modalInfo';
import SpinnerLoading from '../../../components/spinner';
import { useNavigate } from 'react-router';
import { DeleteObra } from '../../../services/obraService';

interface ObraItemProps{
    data: Obra;
}

export default function ObraListItem({data}: ObraItemProps){
    const [loading, setLoading] = useState(false);
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagen, setImagen] = useState<any>();

    useEffect(() => {
        setEdificio(data);
        if (data?.imagenes?.length && data?.imagenes?.length > 0){
            let imagenPrincipal = data?.imagenes.find(o => o.seccionId == 1);
            setImagen(imagenPrincipal?.url);
        } 
    }, []);  

    const eliminarObra = (id: string) => {
        let btnConfirmar = document.getElementById(`btnEliminarObra_${id}`);
        btnConfirmar?.click();
    }

    const confirmarEliminar = async () => {
        setLoading(true);
        await DeleteObra(edificio?.id!)
        setLoading(false)
        setTimeout(() => {
            setLoading(false)
            let btnConfirmar = document.getElementById(`btnModalInfoObra`);
            btnConfirmar?.click();
        }, 2000);
    }
           
    const navigate = useNavigate();

    const redirect = (url: string) => {
        navigate(url);
    }
       
    const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {        
        event.currentTarget.src = "https://placehold.co/400x400?text=...";
    };

    return (
        <div>
            <div className={`d-flex justify-content-between align-items-center container-fluid border border-info border-bottom-0 p-1 ${style.itemContainer} `}>
                <div className="d-flex justify-content-between gap-2  align-items-center">
                    <div className={style.imagen}>
                        <img src={imagen || imgDefault} alt="ImagenObra" onError={imageOnLoadHandler}/>
                    </div>
                    <h2 className={style.nombre}>{edificio?.nombre}</h2>
                    <p className="d-none d-md-block m-0"> ({edificio?.direccion})</p>
                </div>
                <div className="d-flex justify-content-between px-1">
                    <button className="bg-success" onClick={() => redirect(`/propiedad-edit/?id=${edificio?.id}`)}><FaPencil color="white" size={20}></FaPencil></button>
                    <button className="bg-danger" onClick={() => eliminarObra(`${edificio?.id}`)}><FaTrashCan color="white" size={20} ></FaTrashCan></button>
                </div>
            </div>
            <MyModalConfirm idButton={`btnEliminarObra_${edificio?.id?.toString()}`} titulo={edificio?.nombre} mensaje={`¿Desea eliminar la obra ${edificio?.nombre}?`} callback={confirmarEliminar} show={true}></MyModalConfirm>
            <MyModalInfo idButton="btnModalInfoObra" titulo={"Confirmacion"} mensaje="Obra eliminada con éxito" headerColor="success" show={true}></MyModalInfo>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </div>
    )
}