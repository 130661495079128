import { Etapa } from "../interfaces/etapa";
import icon01 from "../media/icons/obra/excavator_light.png";
import icon02 from "../media/icons/obra/construction_light.png";
import icon03 from "../media/icons/obra/brickwall_light.png";
import icon04 from "../media/icons/obra/work-in-progress_light.png";
import icon05 from "../media/icons/obra/deal_light.png";

export const MockEtapas: Etapa[] = 
[
    {id: 1, descripcion: 'Movimiento de suelos', imagen: icon01 },
    {id: 2, descripcion: 'Estructura de hormigón', imagen: icon02 },
    {id: 3, descripcion: 'Mampostería e Instalaciones', imagen: icon03 },
    {id: 4, descripcion: 'Terminaciones', imagen: icon04 },
    {id: 5, descripcion: 'Entregado', imagen: icon05 }
]