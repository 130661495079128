import { useEffect, useState } from 'react';
import { MockBanners } from '../../../mocks/banners'
import { GetAllSectionsByType } from '../../../services/sectionService';
import AdminBanners from '../admin-banner'
import style from './style.module.css'
import { Seccion } from '../../../interfaces/seccion';
import CardBannerAdmin from '../../../components/cardBannerAdmin';

export default function AdminAlquilerTemporario(){
    const [banners, setBanners] = useState<Seccion[]>();
    const [banner, setBanner] = useState<Seccion>();
    const [edit, setEdit] = useState<boolean>(false);

    useEffect(() => {
        getBanners();
    }, []);
    
    const getBanners = async () => {
        let banners = await GetAllSectionsByType(4);
        setBanners(banners);
    }

    const editBanner = async (element: Seccion) => {
        setBanner(element);
        setEdit(true);
    }

    const goBack = () => {
        setEdit(false);
    }

    const newBanner = () => {
        setBanner(undefined);
        setEdit(true);
    }

    const renderBanners = () => banners?.map((v, i) => <div className="col-12 col-md-4 my-2" key={i}><CardBannerAdmin info={v} editBanner={editBanner} tipoSeccion="Alquiler"></CardBannerAdmin></div>)

    return (
        <div className={style.adminHomepage}>
            <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h2 className={style.title}>ADMINISTRACIÓN INVERSIONES</h2>
                {!edit?<button className={style.btnAgregar} onClick={newBanner}>
                    Agregar
                </button>:''}
            </div>
            <div className="d-flex justify-content-between align-items-center m-4 w-100">
                <h5>ALQUILERES TEMPORARIOS</h5>
            </div>
            {!edit?<div className="row mb-3">
                {banners? renderBanners():''}
            </div>:''}
            {edit?<div>
                <AdminBanners data={banner} goBack={goBack} tipoSeccion="Alquiler"></AdminBanners> 
            </div>:''}
        </div>
    )
}