import Header from "../../components/header";
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";
import ReturnTop from "../../components/return-top";
import TopSection from "../servicios/top-section";
import ServicesSection from "./services-section";
import WhatsappButton from "../../components/whatsapp-button";

export default function Servicios(){
    const [styleTop, setStyleTop] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }
    
    const navItemActive = NavItemActive.Fideicomisos;
    return (
        <div>
            <Header navItemActive={navItemActive} opacity={true}></Header>
            <TopSection></TopSection>
            <ServicesSection></ServicesSection>
            <Footer></Footer>
            <Copyright></Copyright>
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}