import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { useForm } from "react-hook-form";
import { ArchivoImagen } from "../../../interfaces/archivoImagen";
import { FaTrashCan } from "react-icons/fa6";

interface AmenitiesProps{
    data: Obra;
    mensaje?: string;  
    guardarAmenities: any;     
}

export default function Amenities({data, mensaje, guardarAmenities}: AmenitiesProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagenes, setImagenes] = useState<ArchivoImagen[] | undefined>(edificio?.imagenes?.filter(e => e.seccionId == 4));
    const [file1, setFile1] = useState<any>();
    const [file2, setFile2] = useState<any>();
    const [file3, setFile3] = useState<any>();
    const [file4, setFile4] = useState<any>();
    const [uploadfile1, setUploadFile1] = useState<any>();
    const [uploadfile2, setUploadFile2] = useState<any>();
    const [uploadfile3, setUploadFile3] = useState<any>();
    const [uploadfile4, setUploadFile4] = useState<any>();
    
    useEffect(() => {
        setEdificio(data);
        let imagenesAmenities = edificio?.imagenes?.filter(e => e.seccionId == 4);
        setImagenes(imagenesAmenities);
        setImagesObra();
    }, []);  
 
    const form = useForm({
        defaultValues: {
            imagen1: file1 || imgDefault,
            imagen2: file2 || imgDefault,
            imagen3: file3 || imgDefault,
            imagen4: file4 || imgDefault,
            Info1: edificio?.amenitiesObras?.[0]?.descripcion || '',
            Info2: edificio?.amenitiesObras?.[1]?.descripcion || '',
            Info3: edificio?.amenitiesObras?.[2]?.descripcion || '',
            Info4: edificio?.amenitiesObras?.[3]?.descripcion || '',
            tituloAmenities: edificio?.tituloAmenities,
            descripcionAmenities: edificio?.descripcionAmenities
        }
    })

    async function setImagesObra(){
        if(imagenes && imagenes.length > 0){
            for (let index = 0; index < imagenes.length; index++) {
                await setImageFile((index+1).toString(), imagenes[index].url, false);                
            }
        }
    }

    async function handleChange(e: any) {
        e.preventDefault();
        let inputId = e.currentTarget.id;        
        const target= e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];

        if(file){
            await setImageFile(inputId[inputId.length-1], URL.createObjectURL(e.target.files[0]), true, (e.target.files))
        }
    }

    async function setImageFile(inputId: string, url: any, upload: boolean, file?: any){
        switch (inputId) {
            case '1':
                await setFile1(url);
                if(upload) { setUploadFile1(file) };
                break;
            case '2':
                await setFile2(url);
                if(upload) { setUploadFile2(file) };
                break;
            case '3':
                await setFile3(url);
                if(upload) { setUploadFile3(file) };
                break;
            case '4':
                await setFile4(url);
                if(upload) { setUploadFile4(file) };
                break;        
            default:
                break;
        }
    }

    const removeImg = async (e: any) => {
        let inputId = e.currentTarget.id;
        await setImageFile(inputId, undefined, false);
    }

    const guardar = async (formData: any) => {
        formData = {
            ...formData,
            imagen1: uploadfile1? uploadfile1: file1,
            imagen2: uploadfile2? uploadfile2: file2,
            imagen3: uploadfile3? uploadfile3: file3,
            imagen4: uploadfile4? uploadfile4: file4,
        }
        guardarAmenities(formData);
    }

    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;

    return (
        <section className={style.detailSection}>
            <form onSubmit={handleSubmit(guardar)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className={style.detailBox}>
                                <div className={style.sectionTitle}>
                                        <label htmlFor="info" className="my-3">Título
                                                <input type="text" {...register("tituloAmenities", {required: true})} id="Info1" placeholder="Ingrese un título" 
                                                    className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                                                {errors.tituloAmenities?.type === 'required' && <p className="text-danger mt-2"> El título es requerido</p>} 
                                        </label> 
                                </div>
                                <label htmlFor="info" className="my-3">Características
                                    <ul>
                                        <input type="text" {...register("Info1")} id="Info1" placeholder="Ingrese una caracteristica" 
                                            className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                                        <input type="text" {...register("Info2")} id="Info2" placeholder="Ingrese una caracteristica" 
                                            className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                                        <input type="text" {...register("Info3")} id="Info3" placeholder="Ingrese una caracteristica" 
                                            className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                                        <input type="text" {...register("Info4")} id="Info4" placeholder="Ingrese una caracteristica" 
                                            className={`form-control my-2 border border-primary ${style.formControlNombre}`} />
                                    </ul>
                                </label> 
                                <label htmlFor="info" className="my-3">Descripción
                                    <div className={style.scrollNoneBox}>
                                        <textarea {...register("descripcionAmenities", {required: true})} id="descripcionAmenities" 
                                            placeholder="Ingrese una descripcion" className={`form-control border border-primary ${style.formControlNombre}`} />
                                        {errors.descripcionAmenities?.type === 'required' && <p className="text-danger mt-2"> El campo descripcion es requerido</p>}     
                                    </div>
                                </label>
                                <div className="col-md-12 text-center mt-5">
                                    <button type="submit" id="amenitiesSubmit" className={`${style.boton}`} hidden>Guardar</button>
                                </div>                                  
                            </div>
                        </div>
                        <div className="row col-lg-5 d-flex justify-content-center align-items-start">
                            <div className="col-12 col-md-6 mb-2">
                                <div className="d-flex gap-1">
                                    <input className="form-control bg-dark text-light border border-primary" type="file"
                                        id="formFile1" {...register("imagen1")} onChange={handleChange} accept="image/*"/>
                                    <button id="1" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                                </div>
                                <img className="img-modal" src={file1 || imgDefault} alt={edificio?.nombre}
                                    width="100%" height="100%"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imgDefault;
                                    }} />
                            </div>
                            <div className="col-12 col-md-6 mb-2">
                                <div className="d-flex gap-1">
                                    <input className="form-control bg-dark text-light border border-primary" type="file"
                                        id="formFile2" {...register("imagen2")} onChange={handleChange} accept="image/*"/>
                                    <button id="2" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                                </div>
                                <img className="img-modal" src={file2 || imgDefault} alt={edificio?.nombre}
                                    width="100%" min-height=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imgDefault;
                                    }} />
                            </div>
                            <div className="col-12 col-md-6 mb-2">
                                <div className="d-flex gap-1">
                                    <input className="form-control bg-dark text-light border border-primary" type="file"
                                        id="formFile3" {...register("imagen3")} onChange={handleChange} accept="image/*"/>
                                    <button id="3" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                                </div>
                                <img className="img-modal" src={file3 || imgDefault} alt={edificio?.nombre}
                                    width="100%" min-height=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imgDefault;
                                    }} />
                            </div>
                            <div className="col-12 col-md-6 mb-2">
                                <div className="d-flex gap-1">
                                    <input className="form-control bg-dark text-light border border-primary" type="file"
                                        id="formFile4" {...register("imagen4")} onChange={handleChange} accept="image/*"/>
                                    <button id="4" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                                </div>
                                <img className="img-modal" src={file4 || imgDefault} alt={edificio?.nombre}
                                    width="100%" min-height=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imgDefault;
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    )
}