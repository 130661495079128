import { ClaveValor } from "../interfaces/claveValor";

export const MockParametros: ClaveValor[] = [
    {
      id: 1,
      clave: "Logo",
      valor: "./img/logo"
    },
    {
      id: 2,
      clave: "Nombre",
      valor: "Estudio Mayocchi"
    },
    {
      id: 3,
      clave: "Correo",
      valor: "maximiliano_hermosilla@hotmail.com"
    },
    {
      id: 4,
      clave: "BackgroundColor",
      valor: "#033a66"
    },
    {
      id: 5,
      clave: "FirstColor",
      valor: "#033a66"
    },
    {
      id: 6,
      clave: "SecondColor",
      valor: "#0B1C35"
    },
    {
      id: 7,
      clave: "ThirdColor",
      valor: "#F9F2EC"
    },
    {
      id: 8,
      clave: "FourthColor",
      valor: "#e5d2c1"
    },
    {
      id: 9,
      clave: "FifthColor",
      valor: "#8c7041"
    },
    {
      id: 10,
      clave: "MetrosConstruidos",
      valor: "60000"
    },
    {
      id: 11,
      clave: "DepartamentosEntregados",
      valor: "4450"
    },
    {
      id: 12,
      clave: "EdificiosConstruidos",
      valor: "28"
    },
    {
      id: 13,
      clave: "AñosDeTrayectoria",
      valor: "30"
    }
  ]