import style from "./style.module.css";
import { Info } from "../../interfaces/info";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

interface InfoProps{
    data?: Info;
    idSeccion?: string;
}

export default function CardInversiones({data, idSeccion}: InfoProps){
    const [infoInversion, setInfo] = useState(data);

    useEffect(() => {
        setInfo(data);
    }, []);
    
    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/inversiones/?id=inversiones0${infoInversion?.id}`);
    }


    return(
        <div className="col-lg-4 d-flex justify-content-between mb-2">
        <div className={`d-flex flex-column justify-content-between ${style.item}`}>
            <div className="container d-flex flex-column justify-content-center align-items-center mb-4">  
                <h3 className={style.titleText} data-id="2430" data-clave="depto-subtit">
                    {infoInversion?.title}
                </h3>               
            </div>
            <h2 className={style.subtitleText}>
                {infoInversion?.subtitle} 
            </h2>
            <div className="container d-flex justify-content-center align-items-end mt-4">
                <button className={`${style.buttonMore}`} onClick={redirect}>
                 Ver más
                </button>
            </div>
        </div>
    </div>
    )
}