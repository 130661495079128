
import AdminAlquilerTemporario from '../admin-alquileres'
import AdminGeneral from '../admin-general'
import AdminHomepage from '../admin-homepage'
import AdministracionObras from '../obras'
import style from './style.module.css'

export default function Accordion(){
    
    return(
        <div className={style.accordion}>
            <div className="container-fluid accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                           <p className={style.accordionText}>GENERAL</p>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse px-1" aria-labelledby="headingOne">
                        <div className="accordion-body p-0">
                            <AdminGeneral></AdminGeneral>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                           <p className={style.accordionText}>HOMEPAGE</p>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse  px-1" aria-labelledby="headingTwo">
                        <div className="accordion-body p-0">
                            <AdminHomepage></AdminHomepage>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <p className={style.accordionText}>ALQUILER TEMPORARIO</p>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse  px-1" aria-labelledby="headingThree">
                        <div className="accordion-body p-0">
                            <AdminAlquilerTemporario></AdminAlquilerTemporario>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <p className={style.accordionText}>OBRAS</p>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse show  px-1" aria-labelledby="headingFour">
                        <div className="accordion-body p-0">
                            <AdministracionObras></AdministracionObras>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}