import Header from "../../components/header";
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";
import ReturnTop from "../../components/return-top";
import { Info } from "../../interfaces/info";
import imagen from "../../media/img/nosotros/nosotros.png";
import imagenFundamentos from "../../media/img/nosotros/nosotros001.jpg";
import Banner from "./banner";
import ValoresSection from "./valores-section";
import SectionTextLeft from "../../components/sectionTextLeft";
import Consulta from "../../components/consulta";
import WhatsappButton from "../../components/whatsapp-button";

export default function Nosotros(){
    const [styleTop, setStyleTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }

    const infoSection: Info =  {
        id: 1,
        title: 'Más de 30 años construyendo',
        subtitle: '',
        text: `Transmitimos la pasión por la construcción de generación en generación`,
        imagen: imagen
    };

    const infoSectionFundamentos: Info =  {
        id: 1,
        title: 'Construimos sobre confianza',
        subtitle: ``,
        text: `
        Somos una empresa familiar dedicada de forma ininterrumpida desde el año 1992 a desarrollar emprendimientos inmobiliarios en la Ciudad de La Plata. 
        El sistema que utilizamos es el de Construcción al Costo por Administración, que preserva los intereses de nuestros clientes mejor que ninguna otra figura legal.

        Nuestro principal activo es la confianza depositada por nuestra amplia cartera de inversores y proveedores, que nos acompañan en un camino de 31 años en los que supimos entregar, llave en mano, más de 780 departamentos en más de 25 edificios.

        Sabemos  que cada proyecto es más que una construcción, es un paso importante en la vida de cada uno de los que nos involucramos. Por eso nos motiva conocer y entender las necesidades de cada integrante, para que ese paso sea firme y seguro. Nuestra larga trayectoria se ha forjado en la creación de vínculos sólidos y perdurables, y nos comprometemos a seguir construyendo por ese camino.

        Si estás buscando tu hogar, una inversión, o un lugar donde tus ideas tomen forma, estamos aquí para ayudarte.
        `,
        imagen: imagenFundamentos
    };

    const navItemActive = NavItemActive.Nosotros;
    return (
        <div>
            <Header navItemActive={navItemActive} opacity={true}></Header>
            <Banner data={infoSection}></Banner>
            <SectionTextLeft info={infoSectionFundamentos}></SectionTextLeft>
            <ValoresSection></ValoresSection>
            <Consulta data={''}></Consulta>
            <br />
            <Footer></Footer>
            <Copyright></Copyright>
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}