import { Seccion } from "../interfaces/seccion";
import imgBanner from "../media/img/banner.gif"

export const MockBannerGif: Seccion[] = 
[
    {
        id: 1,
        titulo: 'Desarrollos inmobiliarios',
        subtitulo: '',
        descripcion: 'La Plata',
        //imagenUrl: 'https://i.imgur.com/jlAT3os.gif',
        imagenUrl: 'https://i.imgur.com/cEraWNZ.gif',
        tipoSeccion: {
            id: 1,
            descripcion: 'Banner'
        }
    }
]