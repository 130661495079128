import { Parametro } from "../../interfaces/parametro";
import { GetAllParametros } from "../../services/parametroService";
import { enviroment } from "../../interfaces/enviroment";

export const SetParameters = async () => {
  let root: any = document.querySelector(':root');
  var rootStyles = getComputedStyle(root!);
  //let parametrosStorage = localStorage.getItem("parametros") ? JSON.parse(localStorage.getItem("parametros")!) : {};
  
  const getParametros = async () => {
    let parametros;
    let firstColor = '#033A66';
    let secondColor = '#0B1C35';
    let thirdColor = '#F9F2EC';
    let fourthColor = '#e5d2c1';
    let fifthColor = '#8C7041';
    let bgColor = '#175873';
    let correo = 'info@estudiomayocchi.com';
    
    if (enviroment.demo()){
      parametros = localStorage.getItem("parametros") ? JSON.parse(localStorage.getItem("parametros")!) : {};
      firstColor = parametros?.firstColor || '#033A66';
      secondColor = parametros?.secondColor || '#0B1C35';
      thirdColor = parametros?.thirdColor || '#F9F2EC';
      fourthColor = parametros?.fourthColor || '#e5d2c1';
      fifthColor = parametros?.fifthColor || '#8C7041';
      bgColor = parametros?.bgColor || '#175873';
      correo = parametros?.correo || 'info@estudiomayocchi.com';
    }
    else{
      parametros = await GetAllParametros();
      firstColor = parametros?.find(p => p.clave == 'FirstColor')?.valor || '#033A66';
      secondColor = parametros?.find(p => p.clave == 'SecondColor')?.valor || '#0B1C35';
      thirdColor = parametros?.find(p => p.clave == 'ThirdColor')?.valor || '#F9F2EC';
      fourthColor = parametros?.find(p => p.clave == 'FourthColor')?.valor || '#e5d2c1';
      fifthColor = parametros?.find(p => p.clave == 'FifthColor')?.valor || '#8C7041';
      bgColor = parametros?.find(p => p.clave == 'BackgroundColor')?.valor || '#175873';
      correo = parametros?.find(p => p.clave == 'Correo')?.valor || 'info@estudiomayocchi.com';
    }
    

    root?.style.setProperty('--first-color', firstColor);
    root?.style.setProperty('--second-color', secondColor);
    root?.style.setProperty('--third-color', thirdColor);
    root?.style.setProperty('--fourth-color', fourthColor);
    root?.style.setProperty('--fifth-color', fifthColor);
    root?.style.setProperty('--dark-second-color', bgColor);

    const parametro: Parametro = {
      nombre: "Estudio Mayocchi",
      correo: correo,
      logo: "./logo",
      firstColor: firstColor,
      secondColor: secondColor,
      thirdColor: thirdColor,
      fourthColor: fourthColor,
      fifthColor: fifthColor,
      bgColor: bgColor
    }
    localStorage.setItem("parametros", JSON.stringify(parametro));
  }
  
  await getParametros();
}