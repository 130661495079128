import { enviroment } from "../interfaces/enviroment";
import { Obra } from "../interfaces/obra";
import { MockObras } from "../mocks/obras";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";

const BASE_URL: string = enviroment.urlBase() + "api/Admin";
const ENV_DEMO: boolean = enviroment.demo();

export const GetAllObras = async (): Promise<Obra[]> => {
    if (ENV_DEMO){
        return MockObras;        
    }
    const url = `${BASE_URL}/getObrasList`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers:{
                "Access-Control-Allow-Origin": "*",
            }
        });
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        const data: Obra[] = await response.json();
        return data;
    } catch (error) {
        return [];
    }
    
}

export const GetObraById = async (id?: number): Promise<Obra | undefined> => {
    if (ENV_DEMO){
        return MockObras.find(o => o.id == id);
    }
    const url = `${BASE_URL}/${id}`;    
    const response = await fetch(url);
    try {
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        const data: Obra = await response.json();
        return data;        
    } catch (error) {
        return undefined;
    }
}

export const InsertObra = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}/postObra`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    const response = await fetch(url, {
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JwtToken}`
        },
        body: JSON.stringify(data) 
    })
    
    if(response.status == 401){
        UnauthorizedLogout();
    }
    
    if (!response.ok){
        throw new Error(response.statusText);        
    }

    result = await response.json();
    if(response.ok && response.status == 200){
        return result;
    }
}

export const UpdateObra = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}/updateObra`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    const response = await fetch(url, {
        method: "PUT",
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JwtToken}`
        },
        body: JSON.stringify(data) 
    })

    if(response.status == 401){
        UnauthorizedLogout();
    }
    
    if (!response.ok){
        throw new Error(response.statusText);
    }
    result = await response.json();
    if(response.ok && response.status == 200){
        return result;
    }
}

export const InsertImagenObra = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}/postImagen`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    const response = await fetch(url, {
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JwtToken}`
        },
        body: JSON.stringify(data) 
    })
    
    if(response.status == 401){
        UnauthorizedLogout();
    }
        
    if (!response.ok){
        throw new Error(response.statusText);        
    }

    if(response.ok && response.status == 201){
        return response;
    }
}

export const DeleteImagenObra = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}/deleteImagen`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    const response = await fetch(url, {
        method: "DELETE",
        headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JwtToken}`
        },
        body: JSON.stringify(data) 
    })    
    
    if(response.status == 401){
        UnauthorizedLogout();
    }
    
    if (!response.ok){
        throw new Error(response.statusText);        
    }

    if(response.ok && response.status == 201){
        return response;
    }
}

export const DeleteObra = async (id: number): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}/${id}`;
    const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(id) 
        })
                
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }   
        //result = await response.json();
        if(response.ok && response.status == 200){
            return response;
        }     
    } catch (error) {
        return undefined;
    }    
}