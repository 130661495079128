import style from "./style.module.css";
import { useEffect, useState } from "react";
import { ClaveValor } from "../../interfaces/claveValor";

interface InfoProps{
    data?: ClaveValor[];
}

export default function CardContacto({data}: InfoProps){
    const [info, setInfo] = useState(data);
    
    useEffect(() => {
        setInfo(data);
    }, []);

    const renderInfo = () => data?.map((v, i) => <div key={i} className="d-flex w-100 gap-2 h-100"><h5>{v.clave}</h5><p>{v.valor}</p></div>)

    return(
        <div className="col-12 col-lg-4 d-flex justify-content-center mb-3 align-items-center">
            <div className={`d-flex flex-column justify-content-center align-items-center w-100 ${style.item}`}>
                {renderInfo()}
            </div>
        </div>
    )
}