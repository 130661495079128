import CardContacto from '../../../components/cardContacto';
import style from './style.module.css'

export default function ContactoSection(){
    
    const infoList: any[] =  [];
    infoList.push([{clave: 'Email: ', valor: 'info@estudiomayocchi.com'},{clave: 'Tel: ', valor: '0221 4223565'},{clave: 'Whatsapp: ', valor: '+542213146751'}]);
    infoList.push([{clave: '', valor: ''},{clave: 'Dirección: ', valor: 'Calle 54 N°367 1/2. La Plata. Buenos Aires, Argentina.'},{clave: '', valor: ''}]);
    infoList.push([{clave: '', valor: ''},{clave: 'Horario: ', valor: `Lunes a Viernes 08 a 17hs.  
      
                                                Reunión previa requerida.`},{clave: '', valor: ''}]);

    const renderContactos = () => infoList?.map((v, i) => <CardContacto data={v} key={i}></CardContacto>)
    return (
        <section className={style.section}>
            <div className="container">                
                <div className="row">
                    {infoList? renderContactos(): ''}
                </div>
            </div>
        </section>
    )
}