import { useState } from "react";
import { User } from "./interfaces/user";
import { HashRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from "./utils/protectedRoute";
import { ContactoRoute, EdificiosRoute, ServiciosRoute, InicioRoute, NosotrosRoute, PropiedadRoute, InversionesRoute, PropiedadABMRoute, AdministracionRoute, LoginRoute } from "./routes";
import ScrollToTop from "./utils/scrollToTop";

export default function Router(){
    const [user, setUser] = useState<User>();
  
    let usuario: User = {id: 1, nombreUsuario: "Maxi", email: "Administrador"}
    const login = () => {
      setUser(usuario)
    }
  
    const logout = () => setUser(undefined);
  
    return (
        <HashRouter>
            <ScrollToTop></ScrollToTop>
            <Routes>
                <Route index element={<InicioRoute></InicioRoute>}></Route>
                <Route path="/" element={<InicioRoute></InicioRoute>}></Route>                
                <Route path="/inicio" element={<InicioRoute></InicioRoute>}></Route>
                <Route path="/login" element={<LoginRoute></LoginRoute>}></Route>
                <Route path="/nosotros" element={<NosotrosRoute></NosotrosRoute>}></Route>
                <Route path="/obras" element={<EdificiosRoute></EdificiosRoute>}></Route>
                <Route path="/inversiones" element={<InversionesRoute></InversionesRoute>}></Route>
                <Route path="/servicios" element={<ServiciosRoute></ServiciosRoute>}></Route>
                <Route path="/contacto" element={<ContactoRoute></ContactoRoute>}></Route>
                <Route path="/propiedad" element={<PropiedadRoute></PropiedadRoute>}></Route>
                <Route element={<ProtectedRoute isAllowed={!!usuario && usuario.email == "Administrador"}/>}>
                    <Route path="/administracion" element={<AdministracionRoute></AdministracionRoute>}></Route>                    
                    <Route path="/propiedad-edit" element={<PropiedadABMRoute></PropiedadABMRoute>}></Route>
                </Route>
                <Route path="*" element={<InicioRoute></InicioRoute>} />
            </Routes>
        </HashRouter>
    )
}