import Contacto from "../pages/contacto";
import Edificios from "../pages/edificios";
import Servicios from "../pages/servicios";
import Home from "../pages/home";
import Nosotros from "../pages/nosotros";
import Propiedad from "../pages/propiedad";
import Inversiones from "../pages/inversiones";
import PropiedadABM from "../pages/propiedad-abm";
import Administracion from "../pages/administracion";
import Login from "../pages/login";

export const InicioRoute = () => <Home></Home>
export const LoginRoute = () => <Login></Login>
export const AdministracionRoute = () => <Administracion></Administracion>
export const NosotrosRoute = () => <Nosotros></Nosotros>
export const EdificiosRoute = () => <Edificios></Edificios>
export const ServiciosRoute = () => <Servicios></Servicios>
export const InversionesRoute = () => <Inversiones></Inversiones>
export const ContactoRoute = () => <Contacto></Contacto>
export const PropiedadRoute = (data: any) => <Propiedad></Propiedad>
export const PropiedadABMRoute = (data: any) => <PropiedadABM></PropiedadABM>