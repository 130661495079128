import style from './style.module.css';
import { useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import { useNavigate } from "react-router";
import ObraListItem from '../obras-list-item';
import { GetAllObras } from '../../../services/obraService';
import SpinnerLoading from '../../../components/spinner';

export default function AdministracionObras(){
    const [loading, setLoading] = useState(false);
    const [edificios, setEdificios] = useState<Obra[]>();

    const fetchEdificios = async () => {
        setLoading(true);
        let lista: Obra[] = await GetAllObras();
        let listaEdificios = lista;
        setEdificios(listaEdificios);
        setLoading(false);
    }

    useEffect(() => {
        fetchEdificios();
    }, []);

    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/propiedad-edit`);
    }
    
    const info = { title: 'NUESTROS PROYECTOS', subtitle: ''};

    const renderEdificios = () => edificios?.map((v, i) => <ObraListItem data={v} key={i}></ObraListItem>)    

    return (
        <div className={style.mainContainer}>
            <div className="d-flex justify-content-between align-items-center w-100 my-2">
                <h2 className={style.title}>ADMINISTRACIÓN DE OBRAS</h2>
                <button className={style.buttonProyectos} onClick={redirect}>
                    Agregar
                </button>
            </div> 
            <ul className="container-fluid">
                <div className="d-flex justify-content-between mt-5" style={{width: "100%"}}>
                    <h2 className={style.textHeader}>Nombre de obra</h2>
                    <h2 className={style.textHeader}>Acciones</h2>
                </div>
                {edificios?renderEdificios():''}
            </ul>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </div>
    )
}