import { Info } from '../../../interfaces/info';
import style from './style.module.css'
import image from '../../../media/img/servicios/servicios_banner.png'

export default function TopSection(){

    const infoSection: Info =  {
        id: 1,
        title: 'Construimos tus proyectos',
        subtitle: 'Tu visión, nuestra realidad',
        text: ``,
        imagen: image
    };

    return (
        <div className={style.section}>
            <div>
                <img src={infoSection?.imagen} alt="Banner" />
            </div>
            <div className={style.topSection}>
                <div className={`${style.sectionTitle}`}>
                    <h2 className={` ${style.title}`}>
                        {infoSection?.title}
                    </h2>
                    <span className={` ${style.subtitle}`}>
                        {infoSection?.subtitle}
                    </span>
                </div>
                <div className={`container ${style.textContainer}`}>
                    <p className={` ${style.text}`}>
                        {infoSection?.text}
                    </p>
                </div>
            </div>
        </div>
    )   
}