import style from "./style.module.css";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CardBanner from "../../../components/cardBanner";
import { useEffect, useState } from "react";
import { GetAllSectionsByType } from "../../../services/sectionService";
import { Seccion } from "../../../interfaces/seccion";
import { MockBannerGif } from "../../../mocks/bannerGif";

export default function TopBanner(){
    const [banners, setBanners] = useState<Seccion[]>();

    useEffect(()=>{
        fetchBanners();
    }, [])

    const fetchBanners = async () => {
        let bannersResponse = await GetAllSectionsByType(1);
        //bannersResponse.pop();
        //let bannersResponse = await MockBannerGif;
        setBanners(bannersResponse);
    }

    const options = {
        margin: 0,
        responsiveClass: true,
        nav: true,
        dots: true,
        autoplay: true,
        navContainerClass: `owl-nav d-none`,
        dotsClass: `owl-dots ${style.customDots}`,
        dotClass: `owl-dot ${style.buttonDot}`,
        controlsClass: "owl-controls",        
        smartSpeed: 1000,
        responsive:{
            0:{
                items:1
            }
        }
    };

    const renderBanners = () => banners?.map((v, i) => <CardBanner info={v} key={i}></CardBanner>)

    return (
        <section className={`top-banner ${style.topBanner}`}>
            {banners? <OwlCarousel className='owl-theme' loop {...options}>
                {renderBanners()} 
            </OwlCarousel> : ''}              
        </section >
        )
}