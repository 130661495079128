import Copyright from "../../components/copyright";
import Header from "../../components/header";
import { NavItemActive } from "../../interfaces/nav-item";
import FormLogin from "./form-login";

export default function Login() {

    const login = (formData: any) => {
        //console.log(formData);
    }
        
    const navItemActive = NavItemActive.Contacto;
    return (
        <div className="d-flex flex-column justify-content-space-between gap-5 pt-5" style={{height: "100vh"}}>
            {/* <Header navItemActive={navItemActive}></Header>   */}
            <FormLogin data={{}} login={login}></FormLogin>         
        </div>
    );
}