import { Seccion } from "../interfaces/seccion";

export const MockAlquileres: Seccion[] = 
[
    {
        id: 1,
        titulo: '',
        subtitulo: '',
        descripcion: '',
        imagenUrl: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/estudiomayocchi%2Fbanner%2F_1692792571154?alt=media&token=5b73b816-312a-4fe5-96bb-8c0d78224c20',
        tipoSeccion: {
            id: 4,
            descripcion: 'Alquiler Temporario'
        }
    },
    {
        id: 2,
        titulo: '',
        subtitulo: '',
        descripcion: '',
        imagenUrl: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/estudiomayocchi%2Fbanner%2F_1692792571155?alt=media&token=962cd94c-444a-4105-b5b7-86a8a74757e8',
        tipoSeccion: {
            id: 4,
            descripcion: 'Alquiler Temporario'
        }
    },
    {
        id: 3,
        titulo: '',
        subtitulo: '',
        descripcion: '',
        imagenUrl: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/estudiomayocchi%2Fbanner%2F_1692792571157?alt=media&token=6089fcdb-d3a8-4b86-a561-997bac04fe3e',
        tipoSeccion: {
            id: 4,
            descripcion: 'Alquiler Temporario'
        }
    },
    {
        id: 4,
        titulo: '',
        subtitulo: '',
        descripcion: '',
        imagenUrl: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/estudiomayocchi%2Fbanner%2F_1692792571153?alt=media&token=472eac6f-6f16-4377-807a-a71a51f2f1c0',
        tipoSeccion: {
            id: 4,
            descripcion: 'Alquiler Temporario'
        }
    }
]