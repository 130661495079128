import style from "./style.module.css";
import { useEffect, useState } from "react";
import { ArchivoImagen } from "../../interfaces/archivoImagen";

interface CardImagenProps{
    data: ArchivoImagen;
}

export default function CardFotoCarousel({data}: CardImagenProps){
    const [imagen, setImagen] = useState<ArchivoImagen>(data);

    useEffect(() => {
        setImagen(data);
    }, []);
           
    const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {        
        event.currentTarget.src = "https://placehold.co/400x400?text=...";
    };

    return (  
        <div className={`item ${style.item} ${style.firstImage}`}>
            <div className={style.projectList}>
                <img className={style.objectCover} src={imagen?.url} alt="Project" onError={imageOnLoadHandler}/>
            </div>                
        </div>
    )
}