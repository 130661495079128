import style from "./style.module.css";
import { useCallback, useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import DetailProgress from "../detail-progress";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { useForm } from "react-hook-form";
import { FaTrashCan } from "react-icons/fa6";

interface DetailSectionProps{
    data: Obra;
    submit: (callback: () => void) => void;
    guardarDetail: any;
}

export default function DetailSection({data, submit, guardarDetail}: DetailSectionProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagen, setImagen] = useState<any>();
    const [etapa, setEtapa] = useState<number | undefined>(); 

    const toggleHelloWorld = useCallback((): void => {
        let btnSubmit = document.getElementById('detailSectionSubmit');
        btnSubmit?.click();
        let btnBannerSubmit = document.getElementById('bannerSubmit');
        btnBannerSubmit?.click();
    }, []);

    useEffect(() => {
        setEdificio(data);
        submit && submit(toggleHelloWorld);
        setEtapa(data?.etapaObraId);
      
        let imagenPrincipal = data?.imagenes?.find(o => o.seccionId == 1);
        setImagen(imagenPrincipal?.url);
        setValue("imagenPrincipal", imagenPrincipal?.url);
    
    }, []);  

    const getEtapa = (event: any)  => {
        setValue("etapa", event);
        setEdificio({
            ...edificio,
            etapaObraId: event
        });
    }   

    const guardar = (formData: any) => {
        guardarDetail(formData);
    }

    const form = useForm({
        defaultValues: {
            direccion: edificio?.direccion || '',
            ciudad: edificio?.ciudad || '',
            fechadeCreación: edificio?.fechadeCreación?.substring(0,10) || '',
            imagenPrincipal: imagen || imgDefault,
            Info1: edificio?.infoDestacada?.[0]?.descripcion || '',
            Info2: data?.infoDestacada?.[1]?.descripcion || '',
            Info3: data?.infoDestacada?.[2]?.descripcion || '',
            Info4: data?.infoDestacada?.[3]?.descripcion || '',
            descripcion: edificio?.descripcion || '',
            archivoPDF: edificio?.archivoPDF || '',
            etapa: edificio?.etapaObraId
        }
    })

    async function handleChange(e: any) {
        const target= e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if(file){
            setImagen(URL.createObjectURL(e.target.files[0]));
        }
    }

    const removeImg = () => {
        setValue("imagenPrincipal", undefined);
        setImagen(undefined);
    }

    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;
    
    return (
        <section className={style.detailSectionAlternative}>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(guardar)}>
                <div className="row">
                    <div className="col-lg-9">
                        <div className={style.detailBox}>
                            <label htmlFor="nombre" className="mb-1">Dirección
                                <input type="text" {...register("direccion")} id="direccion" placeholder="Ingrese dirección" 
                                    className={`form-control border border-primary ${style.formControlNombre}`} />
                            </label>
                            <label htmlFor="nombre" className="mb-1">Ciudad
                                <input type="text" {...register("ciudad")} id="ciudad" placeholder="Ingrese ciudad" 
                                    className={`form-control border border-primary ${style.formControlNombre}`} />
                            </label>
                            <label htmlFor="fechadeCreación" className="mb-5">Fecha
                                <input type="date" {...register("fechadeCreación")} id="fechadeCreación" placeholder="Ingrese fecha" 
                                    className={`form-control border border-primary ${style.formControlNombre}`} />
                            </label>
                            <label htmlFor="info">Información Significante
                                <ul className={style.mb30}>                                    
                                    <input type="text" {...register("Info1")} id="Info1" placeholder="Ingrese una caracteristica" 
                                        className={`form-control  border border-primary ${style.formControlNombre}`} />
                                    <input type="text" {...register("Info2")} id="Info2" placeholder="Ingrese una caracteristica" 
                                        className={`form-control  border border-primary ${style.formControlNombre}`} />
                                    <input type="text" {...register("Info3")} id="Info3" placeholder="Ingrese una caracteristica" 
                                        className={`form-control  border border-primary ${style.formControlNombre}`} />
                                    <input type="text" {...register("Info4")} id="Info4" placeholder="Ingrese una caracteristica" 
                                        className={`form-control  border border-primary ${style.formControlNombre}`} />
                                </ul>
                            </label> 
                            <label htmlFor="descripcion">Descripción
                                <div className={style.scrollNoneBox}>
                                    <textarea {...register("descripcion", {required: true})} id="descripcion" placeholder="Ingrese una descripcion" 
                                        className={`form-control border border-primary ${style.formControlNombre}`} />
                                        {errors.descripcion?.type === 'required' && <span className="text-danger"> El campo descripción es requerido</span>}    
                                </div>
                            </label> 
                            <div className="container d-flex justify-content-center align-items-end my-5">
                                <div className="form-group w-100">
                                    <label className="form-label mt-4">Brochure</label>
                                    <input className="form-control bg-dark text-light border border-primary" type="file" id="formFile" {...register("archivoPDF")} accept=".pdf"/>
                                    {errors.archivoPDF?.type === 'required' && <p className="text-danger"> El archivo es requerido</p>}    
                                </div>
                            </div>  
                            <label className="form-label d-flex justify-content-center align-items-center gap-2">Etapa
                            <input type="number" {...register("etapa")} id="etapa" placeholder="Ingrese una etapa" 
                                className={`form-control border border-primary w-25 m-0`} disabled/>
                                </label> 
                            <div className="col-md-12 text-center">
                            <DetailProgress data={edificio} onClickEtapaSection={getEtapa}></DetailProgress>       
                                <button type="submit" id="detailSectionSubmit" className={`${style.boton}`} hidden>Guardar</button>
                            </div>                              
                        </div>
                    </div>
                    <div className={`${style.imgContainer} col-lg-3 d-flex flex-column justify-content-center`}>
                        <div className="d-flex gap-2">
                            <input className="form-control bg-dark text-light border border-primary" type="file" 
                                id="formFile" {...register("imagenPrincipal")} onChange={handleChange} accept="image/*"/>    
                            <button type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                        </div>                    
                        <img src={imagen || imgDefault} alt={edificio?.nombre} className={style.imagen}/>   
                    </div>
                </div>
                </form>      
            </div>
        </section>
    )
}