import { Obra } from "../interfaces/obra";
import live1 from "../media/img/propiedades/live1.jpg";
import light from "../media/img/propiedades/light.jpg";
import locales from "../media/img/propiedades/04-locales.jpg";
import autentic from "../media/img/propiedades/60-9-y-10-b.jpg";
import alvear from "../media/img/propiedades/alvear.jpg";
import wood from "../media/img/propiedades/ww.jpg";
import cauce from "../media/img/propiedades/cauce1.jpg";
import bloque1 from "../media/img/propiedades/bloque1.jpeg";
import black from "../media/img/propiedades/black1.jpg";
import pisosMoreno from "../media/img/propiedades/moreno1.jpg";
import altosMoreno from "../media/img/propiedades/moreno1-1.jpg";
import babilon from "../media/img/propiedades/babilon.jpg";
import babylon from "../media/img/propiedades/baby2.jpg";

import { MockImagenes } from "./imagenes";

export const MockObras: Obra[] = 
[
        {
            id: 1, 
            nombre: 'Soho Tower', 
            direccion: 'Calle 54 e/ 3 y 4',
            latitud: '-34.913424739633456',
            longitud: '-57.94356124143407',
            descripcion: `El edificio se encuentra en la nueva zona premium denominada La Plata Soho.
            Sistema de construcción al costo`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, una manera distinta de habitar en la ciudad.            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. 
            Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            fechadeCreación: '2023-09-02T00:10:57.353Z',
            etapaObraId: 2,
            imagenes: MockImagenes,
            archivoPDF: { id: 1, url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/Soho%20Tower?alt=media&token=a572f9cc-9ca1-4918-b538-94e4771f9bda', nombreArchivo: 'brochure', obraId: 1},
            infoDestacada: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            amenitiesObras: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 2, 
            nombre: 'Live', 
            direccion: 'Calle 66 e/18 y 19',
            latitud: '',
            longitud: '',
            descripcion: `Edificio Dobleffe Live ubicado en avenida 66 entre 18 y 19 en La Plata.
            Es un proyecto moderno, de espacios amplios, que cuenta con 9 pisos, 11 departamentos de un dormitorio y 16 monoambientes.            
            Además de cocheras cubiertas y descubiertas.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, una manera distinta de habitar en la ciudad.            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. 
            Las unidades se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            fechadeCreación: '2023-09-02T00:10:57.353Z',
            etapaObraId: 2,
            imagenes: MockImagenes,
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            infoDestacada: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            amenitiesObras: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 3, 
            nombre: 'Light Place', 
            direccion: 'Call 49 e/ 10 y 11',
            latitud: '',
            longitud: '',
            descripcion: `LIGHT PLACE es un edificio de atractivo diseño y ubicación. Cuenta con 24 unidades entre las que se distribuyen departamentos de 1 dormitorio de 48,5 m2, departamentos 
            de 2 dormitorios de 96 m2 y duplex de 3 dormitorios. Ofrecemos 10 cocheras cubiertas y 4 descubiertas. Hall de acceso con moderno living recibidor, acceso vehicular con portón automatizado, control por monitoreo, son algunas de las características con las que LIGHT PLACE te recibe.
            Buscamos lograr productos que generen nuevas perspectivas, otorgar un valor diferencial a lo largo del tiempo, unificar nuestras tres premisas: comodidad | diseño | calidad.            
            Nos comprometemos con el cliente seleccionando una gama de productos destacados en el mercado por sus comprobadas prestaciones y sus excelentes terminaciones.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, una manera distinta de habitar en la ciudad.            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. 
            Las unidades se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [],
            etapaObraId: 3,
            infoDestacada: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            amenitiesObras: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 4, 
            nombre: 'Wood Place', 
            direccion: 'Calle 45 e/ 5 y 6',
            latitud: '',
            longitud: '',
            descripcion: `Siguiendo con la línea de edificios “Place”, como Black y Light Place, quisimos generar un proyecto que aporte calidez a la zona, dándole a las 
            personas un refugio en el medio de la ciudad. Proyecto que a través de la madera como uno de los elementos principales, nace su nombre. Con Wood Place quisimos 
            traer, a quienes acuden a nosotros, un lugar en pleno centro que puedan sentirlo como su hogar. Brindándole la calidez y seguridad que un hogar debe tener . 
            Este proyecto, ubicado en 45 entre 5 y 6; cuenta con Semipisos, que se dividen en monoambientes y departamentos de 1 dormitorios. Los últimos cuentan con la 
            posibilidad de armado de un dormitorio y medio.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'wood', url: wood, obraId: 3, seccionId: 1 }],
            etapaObraId: 3,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 5, 
            nombre: 'Locales y oficinas comerciales',
            direccion: '131 Piso PB',
            latitud: '',
            longitud: '',
            descripcion: `El proyecto cuenta con
            Locales comerciales sobre av 131, que cuentan con un amplio salón principal con doble altura, que permite el desarrollo de distintos rubros comerciales. 
            En la cara sur, se encuentra el sector de servicios: acceso al depósito, kitchenette, toilette y patio privado.
            ????Unidades de entre 143m2-151m2.
            Oficinas comunicadas por un corredor, pensadas como un espacio flexible, capaz de subdividirse y adaptarse a tus necesidades laborales. 
            Cuentan con un sector de guardado, kitchenette y baño.
            ????Unidades de entre 41m2-51m2.
            +30 Cocheras privadas y bicicleteros`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'Gorina Joaquin',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'locales', url: locales, obraId: 4, seccionId: 1 }],
            etapaObraId: 4,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 6, 
            nombre: 'Residencias Alvear', 
            direccion: '462',
            latitud: '',
            longitud: '',
            descripcion: `Se trata de un complejo de 14 viviendas, ubicado en la esquina de Carlos Alvear y 21b. De ellas, 4 residencias cuentan con acceso particular 
            sobre calle 21b, mientras que otras 10 acceden a través de un portón automático a calle interna de conjunto, también desde calle 21b.
            Se encuentra a solo 5 minutos del centro de City Bell (Cantilo), y su ubicación es entre Caminos Belgrano y Camino Centenario, lo que permite un fácil y 
            rápido acceso a los distintos puntos de la ciudad de La Plata.
            Se plantean al menos 5 residencias distintas. Todas contarán con espacio semicubierto con parrilla, jardín y pileta.
            Las residencias están distribuidas en dos plantas. En su planta baja encontrarás Cocina, Living-Estar, Toilette, Galería Semicubierta con parrilla, 
            piscina y cochera. En planta alta 1 dormitorio en suite, 2 dormitorios secundarios con placares completos y baño completo.`,         
            imagenes: [{id: 2, descripcion: 'Alvear', url: alvear, obraId: 5, seccionId: 1 }],
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'City Bell',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            etapaObraId: 5,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 7, 
            nombre: 'Autentic', 
            direccion: '60 9 y 10',
            latitud: '',
            longitud: '',
            descripcion: `Te presentamos AUTENTIC, ubicado en una zona clave de la ciudad, a metros de una de las principales avenidas comerciales, a pocas cuadras del Bosque, 
            Parque Saavedra y  Plaza Moreno. Es una zona práctica para vivir, ya que tenes todo lo que necesitas para tu vida en la ciudad cerca!
            En sus primeros 8 niveles tendrá unidades de dos dormitorios al frente, de 73,5m2, y al contra frente, unidades de un dormitorio de 51,50m2 y mono ambientes de 35m2.            
            En los pisos 9 y 10 encontramos unidades duplex, al frente duplex de 2 dormitorios con terraza privada y al contra frente duplex con 3 dormitorios.            
            El edificio contará con 6 cocheras en Planta Baja y un Local de 75m2, que tendrá un subsuelo.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'autentic', url: autentic, obraId: 5, seccionId: 1 }],
            etapaObraId: 3,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]
        },
        {
            id: 8, 
            nombre: 'Vivienda Unifamiliar', 
            direccion: 'Calle 132 Bis y 476',
            latitud: '',
            longitud: '',
            descripcion: `El complejo esta compuesto por tres volúmenes, de los cuales dos de ellos albergan dos viviendas cada uno. Un total de 5 
            viviendas dispuestas a orillas del cauce se implantan siguiendo un esquema que garantiza la privacidad, las amplias visuales y la comodidad 
            de cada uno de sus habitantes.
            Proyectados desde la formalidad de dos volúmenes geométricos superpuestos uno sobre otro, el conjunto cuenta con dos plantas diseñadas 
            en base a su privilegiada ubicación.            
            Brindando espacios cubiertos y semicubiertos de calidad, aprovechando al máximo las visuales, la iluminación natural y la diagramación espacial.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'City Bell',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: cauce, obraId: 5, seccionId: 1 }],
            etapaObraId: 1,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]            
        },
        {
            id: 9, 
            nombre: 'Casa Bloque I', 
            direccion: 'Calle 132 Bis y 476',
            latitud: '',
            longitud: '',
            descripcion: `Una vivienda proyectada desde la formalidad de dos volúmenes superpuestos uno sobre otro, que cuenta con dos plantas diseñadas en base 
            a su privilegiada ubicación.  Espacios cubiertos y semicubiertos de calidad, aprovechando al máximo la potencialidad de las visuales y la iluminación natural.
            La vivienda se desarrolla en dos plantas, sumando un total de 235 m². En planta baja ubicamos un amplio estar comedor con galería frente al cauce. Además, 
            cocina integrada con barra desayunadora, toilette y sector lavadero. Junto al acceso se encuentran la parrilla y la piscina. En planta alta encontramos un 
            dormitorio principal con vestidor, estudio, baño en suite y terraza, dos dormitorios secundarios y un baño completo.`,            
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'City Bell',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: bloque1, obraId: 5, seccionId: 1 }],
            etapaObraId: 2,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
        {
            id: 10, 
            nombre: 'Black Place', 
            direccion: 'Calle 45 e/ 9 y 10',
            latitud: '',
            longitud: '',
            descripcion: `BLACK PLACE es el fiel reflejo de un proyecto que busca lo simple. Combinando materiales de innovación, creando un diseño 
            proporcionado y empleando una reducida gama de tonos, logramos llegar a un delicado resultado. Un edificio de 13 pisos que nos brinda los 
            espacios necesarios para el completo desarrollo de nuestra rutina, ubicado en el centro de la ciudad de La Plata. Destacamos su sencillez 
            plasmada en el diseño de fachada como en el armado de  ambientes interiores. Consideramos a BLACK como un edificio versátil ya que cuenta 
            con la capacidad de albergar numerosas oficinas de proporciones variadas, como asi también otorgar la calidez necesaria para quienes residan en el mismo.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: black, obraId: 5, seccionId: 1 }],
            etapaObraId: 3,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
        {
            id: 11, 
            nombre: 'Pisos de Moreno', 
            direccion: 'Diag. 74 e/ 54 y 55',
            latitud: '',
            longitud: '',
            descripcion: `Un edificio que elige destarcarse por su alta categoría, orientado al uso residencial que pretende disfrutar de espacios amplios, 
            potenciado por su excelente ubicación y visuales. Hablamos de un proyecto que ofrece viviendas de piso completo en los seis primeros niveles y 
            dos viviendas duplex en los pisos 7 y 8/ 9 y 10. Además dispondrá de 3 cocheras cubiertas y 3 descubiertas.
            El proyecto se enfoca en la combinación ideal entre estilo y calidad de vida, con terminaciones de categoría. Quisimos lograr el atractivo 
            en cada departamento, diseñando pisos adaptables a los distintos    momentos de la vida, generando espacios capaces de albergar las actividades 
            de reunión y descanso necesarias para equilibrar la rutina.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: pisosMoreno, obraId: 5, seccionId: 1 }],
            etapaObraId: 3,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
        {
            id: 12, 
            nombre: 'Altos de Moreno', 
            direccion: 'Calle 16 e/ 55 y 56',
            latitud: '',
            longitud: '',
            descripcion: `Departamentos en la zona de Plaza Moreno en calle 16 e/55 y 56 en la Ciudad de La Plata`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: altosMoreno, obraId: 5, seccionId: 1 }],
            etapaObraId: 4,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
        {
            id: 13, 
            nombre: 'Babylon 58', 
            direccion: 'Calle 58 e/ 18 y 19',
            latitud: '',
            longitud: '',
            descripcion: `El emprendimiento cuenta con detalles de categoría y se desarrolla en planta baja y nueve niveles. Todas las unidades cuentan 
            con excelentes condiciones de luminosidad y ventilación, cuyos planos se adjuntan a continuación. Cuenta con acceso peatonal inclusivo, 
            llegando a un amplio hall de entrada. El acceso vehicular dirige hacia dos plantas de estacionamiento con cocheras. `,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: babilon, obraId: 5, seccionId: 1 }],
            etapaObraId: 5,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
        {
            id: 14, 
            nombre: 'Babylon II', 
            direccion: 'Calle 58',
            latitud: '',
            longitud: '',
            descripcion: `Levantamos entre estructuras de hierro y cemento, un espacio u?nico donde conviven y armonizan la modernidad arquitecto?nica y la naturaleza. 
            9 pisos y 27 unidades conectadas por jardines verticales que te invitara?n a vivir tu rutina de una manera co?moda, relajante y diferente.
            Continuando con la esencia de Babylon 58, en este nuevo proyecto, buscamos acercarte a la naturaleza, potenciar los espacios verdes en la ciudad y que 
            vos seas parte. A partir de un disen?o u?nico e innovador, combinamos en equilibrio nuestros materiales junto a los jardines verticales que caracterizan 
            a este proyecto.
            Dentro de las viviendas que conforman el proyecto, podemos encontrar 9 unidades al frente con 2 dormitorios, 9 al contrafrente de 1 dormitorio y 9 monoambientes.`,
            descripcionDepartamentos: `Las unidades del frente de Marcelo T de Alvear se destacan por su increible vista a los grandes espacios verdes de la plaza Rodriguez Peña, 
            una manera distinta de habitar en la ciudad.
            
            La construcción tradicional con materiales nobles y terminaciones de categoría contribuyen a generar percepciones de equilibrio entre interior y exterior. Las unidades 
            se entregan con pisos de madera en living, comedor, circulaciones interiores y dormitorios. Las cocinas y baños serán revestidos en porcelanato de grandes piezas. 
            Todos los departamentos contarán con amplios ventanales de techo a piso de DVH.`,
            tituloAmenities: 'Amenities y Servicios',
            descripcionAmenities: `Soho Tower`,
            ciudad: 'La Plata',
            archivoPDF: { id: 1, url: '', nombreArchivo: 'brochure', obraId: 1},
            imagenes: [{id: 2, descripcion: 'Foto', url: babylon, obraId: 5, seccionId: 1 }],
            etapaObraId: 1,
            amenitiesObras: [ { id: 1, descripcion: "54 e/ 3 Y 4 - La Plata" }, { id: 2, descripcion: "Semipisos | Pisos" }, { id: 3, descripcion: "10 Unidades" }, { id: 4, descripcion: "Enero 2022 - Junio 2026"} ],
            infoDestacada: [ { id: 1, descripcion: "Lobby de acceso jerarquizado" }, { id: 2, descripcion: "2 ascensores" }, { id: 3, descripcion: "Sum con parrilla"}, { id: 4, descripcion: 'Solarium con duchas'} ]   
        },
];