import CardInversiones from "../../../components/cardInversiones";
import { MockInversiones } from "../../../mocks/inversiones";
import style from "./style.module.css";

export default function InversionesHome(){

    const listInfo = MockInversiones;
    const renderInversiones = () => listInfo?.map((v, i) => <CardInversiones data={v} key={i}></CardInversiones>)
    return (
        <section className={style.ourSolutions}>
            <div className={`container mt-5 ${style.container}`}>
                <div className={style.sectionTitle}>
                    {/* <span className={style.editable} data-id="0" data-clave="soluciones-txt">
                        INVERSIONES </span> */}
                    <h2 className={style.editable} data-id="0" data-clave="soluciones-title">
                        Invertí en pesos, capitalizate en dólares
                    </h2>
                    <span className={style.editable}>Personalizá tu inversión de acuerdo a tus intereses</span>
                </div>
                <div className={`row ${style.containerCards}`}>
                    {listInfo? renderInversiones(): ''}
                </div>
            </div>
        </section>
    )
}