import Header from "../../components/header";
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import OurProjects from "../../components/our-projects";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";
import ReturnTop from "../../components/return-top";
import { Obra } from "../../interfaces/obra";
import { GetAllObras } from "../../services/obraService";
import SpinnerLoading from "../../components/spinner";
import WhatsappButton from "../../components/whatsapp-button";

export default function Edificios(){
    const [loading, setLoading] = useState(false);
    const [styleTop, setStyleTop] = useState(false);
    const [edificios, setEdificios] = useState<Obra[]>();

    const fetchEdificios = async () => {
        setLoading(true);
        let lista: Obra[] = await GetAllObras();
        let listaEdificios = lista;
        setEdificios(listaEdificios);
        
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        fetchEdificios();
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }
    
    const info = { title: 'Nuestros proyectos', subtitle: ''};
    const navItemActive = NavItemActive.Edificios;
    return (
        <div>
            <Header navItemActive={navItemActive}></Header>
            {edificios? <OurProjects data={edificios} info={info}></OurProjects>: ''}
            {/* <ViewProject></ViewProject> */}
            <Footer></Footer>
            <Copyright></Copyright>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}