import CardServicio from '../../../components/cardServicio';
import { Info } from '../../../interfaces/info';
import { MockServicios } from '../../../mocks/servicios';
import style from './style.module.css'

export default function ServicesSection(){
    
    const infoList: Info[] =  MockServicios;

    const renderServicios = () => infoList?.map((v, i) => <CardServicio data={v} key={i} contacto={true}></CardServicio>)
    return (
        <section className={style.section}>
            <div className="container">
                
                <div className="row">
                    {infoList? renderServicios(): ''}
                </div>
            </div>
        </section>
    )
}