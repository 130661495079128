import style from "./style.module.css";
import { useEffect, useState } from "react";
import MyModalContacto from "../../../components/modal";
import DetailProjects from "../detail-projects";
import { Obra } from "../../../interfaces/obra";
import { FaRegCircleCheck } from "react-icons/fa6";

interface AmenitiesProps{
    data: Obra;
    mensaje?: string;     
}

export default function Amenities({data, mensaje}: AmenitiesProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [message, setMessage] = useState(`Hola, quiero recibir más información sobre el emprendimiento ${edificio?.nombre}. ¡Muchas gracias!`);

    useEffect(() => {
        setEdificio(data);
        setMessage(`Hola, quiero recibir más información sobre el emprendimiento ${edificio?.nombre}. ¡Muchas gracias!`); 
    }, []);  

    const renderListItem = () => data?.amenitiesObras?.map((v, i) => <li key={i} style={{color: "var(--first-color)"}}><FaRegCircleCheck></FaRegCircleCheck> {v.descripcion}</li>)

    return (
        <section className={style.detailSection}>
            <div className="container-fluid">
                <div className="row">   
                    <div className="col-lg-5 order-lg-1">
                        {edificio.imagenes? <DetailProjects data={edificio?.imagenes} idSeccion={4}></DetailProjects>: ''}
                    </div>                                    
                    <div className="col-lg-7 order-lg-12">
                        <div className={style.detailBox}>
                            <div className={style.sectionTitle}>
                                <h2>{edificio?.tituloAmenities}</h2>
                            </div>                           
                            <ul>
                                {data.amenitiesObras?renderListItem():''}
                            </ul>
                            <div className={`${style.scrollBox} ${style.customScrollbar}`}>
                                <div id="mCSB_1" className={`${style.customLight}`} tabIndex={0}>
                                    <div id="mCSB_1_container" className={style.customContainer} dir="ltr">
                                        <p>{edificio?.descripcionAmenities}</p>
                                    </div>                             
                                </div>
                            </div>
                            <div className="container d-flex justify-content-center align-items-end my-5">
                                <MyModalContacto show={true} mensaje={message}></MyModalContacto>
                            </div>
                        </div>
                    </div>                                         
                </div>
            </div>
        </section>
    )
}