import style from "./style.module.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Info } from "../../interfaces/info";
import MyModalContacto from "../modal";
import imgDefault from "../../media/img/bannerEmpty.jpg";
import { Seccion } from "../../interfaces/seccion";
import DetailProjects from "../../pages/propiedad/detail-projects";
import { ArchivoImagen } from "../../interfaces/archivoImagen";

interface InfoProps{
    info?: Info;
    contacto?: boolean;
    buttonLink?: string;
    buttonText?: string;
    mensaje?: string;
    idSeccion?: string,
    imagenesCarousel?: Seccion[];
}

export default function SectionTextLeft({info, contacto, buttonLink, buttonText, mensaje, idSeccion, imagenesCarousel}: InfoProps){
    const [infoSection, setInfo] = useState(info);
    const [imagenes, setImagenes] = useState<any[]>();
    const [message, setMessage] = useState(`Hola, quiero recibir más información sobre ${info?.title}. ¡Muchas gracias!`);

    useEffect(() => {
        setInfo(info);        
        if(imagenesCarousel){
            let images = imagenesCarousel?.map( i => { return { url: i.imagenUrl, seccionId: i?.tipoSeccion?.id}});
            setImagenes(images);
        }
        setMessage(`Hola, quiero recibir más información sobre ${info?.title}. ¡Muchas gracias!`);
    }, []);

    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/${buttonLink}`);
    }

    return (
        <section className={style.about} id={idSeccion}>
            <div className="container-fluid">
                <div className="row d-flex flex-wrap">
                    <div className="col-lg-6 order-lg-1 d-flex justify-content-start">
                        {imagenes?<DetailProjects data={imagenes} idSeccion={4}></DetailProjects>
                        :<img src={infoSection?.imagen || imgDefault} alt="Info" width="100%" className={style.imagen} />}
                    </div>
                    <div className={`col-lg-6 order-lg-12d-flex justify-content-start gap-2 align-start`}>
                        <div className={`${style.sectionTitle} ${style.editableImg}`}>
                            <h2 className={` ${style.title}`} data-id="2412" data-clave="about-us-txt">
                                {infoSection?.title}
                            </h2>
                            <span className={` ${style.subtitle}`} data-id="2411" data-clave="about-us-tit">
                                {infoSection?.subtitle}
                            </span>
                        </div>
                        <p className={` ${style.editableImg}`} data-id="2413" data-clave="about-us-txttxt">
                            {infoSection?.text}
                        </p>
                        <div className={`d-flex justify-content-center gap-2 ${style.options}`}>
                            {buttonText? <button className={style.buttonVerMas} onClick={redirect}>
                                {buttonText}
                            </button>: ''}                            
                            {contacto?<MyModalContacto show={true} mensaje={message}></MyModalContacto>: ''}
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    )
}