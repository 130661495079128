import { useEffect, useState } from 'react';
import Header from '../../components/header'
import { NavItemActive } from '../../interfaces/nav-item';
import FormLogin from '../login/form-login';
import Accordion from './accordion';
import TopSection from './top-section'
import { AutenticarUsuario } from '../../services/usuarioService';
import MyModalInfo from '../../components/modalInfo';
import Footer from '../../components/footer';


export default function Administracion(){
    const [token, setToken] = useState('');
    const [message, setMessage] = useState();

    useEffect(() => {
        getTokenLocalStorage();
    }, [])
        
    const login = async (formData: any) => {
        let result = '';
        try {
            result = await AutenticarUsuario(formData);    
            if (result){
                //console.log(result);
                localStorage.setItem("token", JSON.stringify(result?.replace(/['"]+/g, '')));
                setToken(result);
            }        
        } catch (error: any) {
            //console.log(error.message);
            setMessage(error.message);
            let btnConfirmar = document.getElementById(`btnModalError`);
            btnConfirmar?.click();
        }    
    }

    const getTokenLocalStorage = () => {
        let token = localStorage.getItem("token")? JSON.parse(localStorage.getItem("token")!): undefined;
        if (token){
            setToken(token);
        }
    }

    const navItemActive = NavItemActive.Fideicomisos;
    return(
        <div>
            <Header navItemActive={navItemActive}></Header>
            {token==''?<FormLogin data={{}} login={login}></FormLogin>: ''}
            {token!=''?<TopSection></TopSection>: ''}
            {token!=''?<Accordion></Accordion>: ''}
            {/* <Footer></Footer> */}
            <MyModalInfo idButton="btnModalError" titulo={"Error"} mensaje={message} headerColor="danger" show={true}></MyModalInfo>
        </div>
    )
}