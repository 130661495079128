import style from "./style.module.css";
import { useEffect, useRef, useState } from "react";
import icon from "../../media/icons/mayocchi.png";

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    latitud: number;
    longitud: number;
    getLocation: any;
}

export default function MapEdit({mapType, mapTypeControl, latitud, longitud, getLocation}: IMap){
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<any>();

    function initMap(zoomLevel: number, address: any): void {
        const myLatlng = new google.maps.LatLng(latitud, longitud);
        if(latitud){
            address = myLatlng;
        }
    
        const map = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
                zoom: zoomLevel,
                center: address,
                draggableCursor: 'pointer',
            }
            );
            
        let marker: google.maps.Marker;
        marker = new google.maps.Marker({
            position: myLatlng,
            map,
        });  
    
        map.addListener("click", (e: any) => {        
            setLocation(e.latLng.lat(), e.latLng.lng());
            placeMarkerAndPanTo(e.latLng, map);
            map.setCenter(e.latLng as google.maps.LatLng);
        });

        function placeMarkerAndPanTo(latLng: google.maps.LatLng, map: google.maps.Map) {
            if (marker){
                marker.setMap(null);
            }

            marker = new google.maps.Marker({
                position: latLng,
                map: map,
            });
            map.panTo(latLng);
            }          
    }

    const defaultMapStart = (): void => {
        const defaultAddress = new google.maps.LatLng(-34.9213165782929, -57.954430231410406);
        initMap(15, defaultAddress);
    }

    const startMap = (): void => {
        if (!map){
            defaultMapStart();
        }
    }

    useEffect(startMap, [map]);

    const setLocation = (latitud: number, longitud: number) => {
        //console.log(latitud , longitud)
        getLocation(latitud , longitud);
    }

    return (
    <div className={`map-container ${style.mapContainer}`}>
        <div id="map" ref={ref} className={`map-container__map ${style.mapContainerMap}`}></div>
    </div>
    );
}