import { useForm } from "react-hook-form";
import style from "./style.module.css";
import { User } from "../../../interfaces/user";

interface UsuarioProps{
    data?: User,
    login: any
}

export default function FormLogin({data, login}: UsuarioProps) {

    const { register, formState: {errors}, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            email: data?.email || '',
            contraseña: data?.contraseña || ''
        }
    });

    const ingresar = async (formData: any) => {
        //console.log(formData)
        await login(formData);
    }

    return (
        <div className="container mw-50 pt-5 h-100" style={{}}>
            <div className={`container-fluid w-100 border border-secondary rounded-4 ${style.divTitle}`}>
                <div>
                    <h1 className="title text-info px-3 py-3 mb-0 text-center">Login</h1>
                    <h3 className="title text-warning p-3 text-center">Iniciar sesión</h3>                    
                </div>   

                <div className="container text-light">
                    <form onSubmit={handleSubmit(ingresar)}>
                        <div className="container my-5">
                            <label className="text-left">Usuario</label>   
                            <input type="email" placeholder="Email..." className="form-control rounded-0" 
                            {...register("email", {required: true})} id="email"/>
                            {errors.email?.type === 'required' && <span className="text-danger"> El campo email es requerido</span>}
                        </div>
                        <div className="container my-5">
                            <label className="text-left">Contraseña</label>   
                            <input type="password" placeholder="Contraseña..." className="form-control rounded-0"
                            {...register("contraseña", {required: true})} id="contraseña"/>    
                            {errors.contraseña?.type === 'required' && <span className="text-danger"> El campo contraseña es requerido</span>}
                        </div>
                        <div className="container text-light w-100 d-flex align-items-center justify-content-center my-5 p-1">
                            <button type="submit" id="btnLoginSubmit">Ingresar</button>    
                        </div>                         
                    </form>
                </div> 
            </div>
        </div>
    );
}