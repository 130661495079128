// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'


const firebaseConfig_old = {
  apiKey: "AIzaSyBhiHIDOjAYy77KXge8m4UX6JOJ1x6q04E",
  authDomain: "digitalproject-demo.firebaseapp.com",
  projectId: "digitalproject-demo",
  storageBucket: "digitalproject-demo.appspot.com",
  messagingSenderId: "943055345188",
  appId: "1:943055345188:web:c5c58359bc0cbb3572aab1",
  measurementId: "G-VXB2GDYYD8"
};

const firebaseConfig = {
  apiKey: "AIzaSyAq23o3EQDkD1C28a5I42DIZ5Iy_5z4PU0",
  authDomain: "estudiomayocchi-5e1ed.firebaseapp.com",
  projectId: "estudiomayocchi-5e1ed",
  storageBucket: "estudiomayocchi-5e1ed.appspot.com",
  messagingSenderId: "315329974105",
  appId: "1:315329974105:web:7d9c255e4a8ccfd7ac8648",
  measurementId: "G-CCG43SW3ZR"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);

export async function uploadFile(file: any, fileName: string){
    const storageRef = ref(storage, fileName);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
}