import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { FaDownload, FaEye, FaRegCircleCheck } from "react-icons/fa6";
import DetailProgress from "../detail-progress";
import { ftpDownload } from "../../../services/ftpService";
import SpinnerLoading from "../../../components/spinner";

interface DetailSectionProps{
    data: Obra;
}

export default function DetailSection({data}: DetailSectionProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagen, setImagen] = useState<any>();
    const [dataPdf, setDataPdf] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEdificio(data);
        if (data?.imagenes?.length && data?.imagenes?.length > 0){
            let imagenPrincipal = data?.imagenes.find(o => o.seccionId == 1);  
            setImagen(imagenPrincipal?.url);
        } 
    }, []);  

    const downloadBrochure = async () => {
        setLoading(true);
        let pdfBytes = await ftpDownload(edificio?.archivoPDF?.url);        
        //let blob = new Blob([pdfBytes], { type: "application/pdf" });
        let blob = base64toBlob(pdfBytes);
        const url = URL.createObjectURL(blob);
        // console.log(blob);   
        var uri = 'data:application/pdf;base64,' + pdfBytes;
        var uriFull = `<html><head><title>your title in here</title></head><body><embed src=${uri} type="" width={"100%"} height={500} /></body></html>`
        let alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.rel = "noreferrer"
        //alink.download = edificio?.archivoPDF?.url;
        setDataPdf(uri);
        alink.click();
        setLoading(false);
        //window.open('data:application/pdf;base64,' + escape(pdfBytes));        
    }

    const viewBrochure = async () => {
        setLoading(true);
        const url = edificio?.archivoPDF?.url?.includes('https://')? edificio?.archivoPDF?.url: `https://www.estudiomayocchi.com/static/media/files/${edificio?.archivoPDF?.url}`        
        let alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.rel = "noreferrer"
        alink.click();
        setLoading(false);
    }
    
    const directDownloadBrochure = async () => {
        setLoading(true);
        let pdfBytes = await ftpDownload(edificio?.archivoPDF?.url);        
        //let blob = new Blob([pdfBytes], { type: "application/pdf" });
        let blob = base64toBlob(pdfBytes);
        const url = URL.createObjectURL(blob);
        // console.log(blob);   
        var uri = 'data:application/pdf;base64,' + pdfBytes;
        //var uriFull = `<html><head><title>your title in here</title></head><body><embed src=${uri} type="" width={"100%"} height={500} /></body></html>`
        let alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.rel = "noreferrer"
        alink.download = edificio?.archivoPDF?.url;
        setDataPdf(uri);
        alink.click();
        setLoading(false);
        //window.open('data:application/pdf;base64,' + escape(pdfBytes));        
    }

    // const base64toBlob = (data: string) => {
    //     // Cut the prefix `data:application/pdf;base64` from the raw base 64
    //     const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    
    //     const bytes = atob(base64WithoutPrefix);
    //     let length = bytes.length;
    //     let out = new Uint8Array(length);
    
    //     while (length--) {
    //         out[length] = bytes.charCodeAt(length);
    //     }
    
    //     return new Blob([out], { type: 'application/pdf' });
    // };

    function openBase64NewTab(base64Pdf: string): void {
        var blob = base64toBlob(base64Pdf);
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        }
      }
      
      function base64toBlob(base64Data: string) {
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
      
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
          const begin = sliceIndex * sliceSize;
          const end = Math.min(begin + sliceSize, bytesLength);
      
          const bytes = new Array(end - begin);
          for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
          }
          byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: "application/pdf" });
      }


    const renderListItem = () => data?.infoDestacada?.map((v, i) => <li key={i} style={{color: "var(--first-color)"}}><FaRegCircleCheck></FaRegCircleCheck> {v.descripcion}</li>)

    return (
        <section className={style.detailSectionAlternative}>
            <div className="container-fluid">
                <div className="row">                   
                    <div className="col-lg-9">
                        <div className={style.detailBox}>
                            <div className={style.sectionTitle}>                               
                                <h2>{edificio?.nombre}</h2>
                            </div>
                            <div className={`${style.addressContainer} d-flex justify-content-center text-center`}>
                                <div>
                                    <h5>{edificio?.direccion}</h5>
                                    <h5>{edificio?.ciudad}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-1"></div>
                                <ul className="col-12 col-sm-6">
                                    {data.infoDestacada?renderListItem():''}
                                </ul>
                                <div className="col-12 col-sm-1"></div>
                                <div className={`${style.scrollNoneBox} col-12 col-sm-4`}>
                                    <p>{edificio?.descripcion}</p>
                                </div>
                            </div>                        
                            <div className="container d-flex justify-content-center align-items-end my-5">
                                {/* <a href={edificio?.archivoPDF?.url} target="_blank"> */}
                                    {edificio?.archivoPDF?.url?<div className="d-flex gap-3">
                                        <button className={style.buttonBrochure} onClick={viewBrochure}>BROCHURE</button>
                                        {/* <button className={style.buttonBrochure} onClick={downloadBrochure}>BROCHURE</button>
                                        <button className={style.buttonBrochure} onClick={viewBrochure}><FaEye></FaEye></button>
                                        <button className={style.buttonBrochure} onClick={directDownloadBrochure}><FaDownload></FaDownload></button> */}
                                        {/* <a href={edificio?.archivoPDF?.url} target="_blank" rel="noreferrer">Descargar</a> */}
                                        </div>:''}
                                {/* </a>  */}
                            </div>
                            <div>
                                {/* {dataPdf?<object data={dataPdf} type="application/pdf" width={"100%"}></object>:''} */}
                                {/* {dataPdf?<embed src={dataPdf} type="" width={"100%"} height={500} />:''} */}
                                {/* {dataPdf?<a href={dataPdf} target="_blank" rel="noreferrer">Descargar</a>:''} */}
                                {/* {dataPdf?<button onClick={(): void => openBase64NewTab(dataPdf)}>Descargar</button>:''} */}
                            </div>
                            <DetailProgress data={edificio}></DetailProgress>                                     
                        </div>
                    </div>
                    <div className={`${style.imgContainer} col-lg-3 order-lg-2 d-flex justify-content-center h-100`}>
                        <img src={imagen || imgDefault} alt={edificio?.nombre} className={style.imagen}/>
                    </div>
                </div>
            </div>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </section>
    )
}