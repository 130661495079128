import style from './style.module.css'
import { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";

interface ModalProps{
    idButton?: string,
    titulo?: string,
    mensaje?: string,
    callback?: any;
    show?: boolean;
}

export default function MyModalConfirm({idButton, titulo, mensaje, callback, show}: ModalProps){    
    const [showModal, setShow] = useState(false);
    const [title, setTitle] = useState(titulo);
    const [message, setMessage] = useState(mensaje);

    useEffect(() => {
        setTitle(title || '');
        setMessage(mensaje || '');
    }, []);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const confirmar = () => {
        callback();
        handleClose();
    }

    return ( 
        <div className={style.modalConfirm}>
            <button id={idButton} className={style.buttonContacto} onClick={handleShow} hidden>                
            </button>
            <Modal show={showModal} onHide={handleClose}
                size="lg"
                centered
                >               
                <Modal.Header closeButton className={style.modalHeader}>
                    <h2>{title}</h2>
                </Modal.Header>
                <Modal.Body className={style.modalBody}>
                    <p>{message}</p>
                </Modal.Body>                
                <Modal.Footer className={style.modalFooter}>
                    <Button variant="danger" onClick={handleClose} id="btnModalClose">Salir</Button>
                    <Button variant="success" onClick={confirmar}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}