import { useForm } from 'react-hook-form';
import style from './style.module.css'
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { useEffect, useState } from 'react';
import { GetAllSectionsByType, InsertSection, UpdateSection } from '../../../services/sectionService';
import { FaChevronLeft, FaTrashCan } from 'react-icons/fa6';
import { uploadFile } from '../../../services/firebase/config';
import { Seccion } from '../../../interfaces/seccion';
import MyModalInfo from '../../../components/modalInfo';
import SpinnerLoading from '../../../components/spinner';
import { UploadImgbb } from '../../../services/imgbbService';

interface BannerProps{
    data?: Seccion;
    goBack?: any;
    tipoSeccion?: string;
}

export default function AdminBanners({data, goBack, tipoSeccion}: BannerProps){
    const [banner, setBanner] = useState<Seccion | undefined>(data);
    const [file, setFile] = useState<any>();
    const [imagen, setImagen] = useState<any>();
    const [uploadedImage, setUploadedImage] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setBanner(data);
        setImagen(data?.imagenUrl);
        setValue("imagenUrl", imagen);
        if(data?.imagenUrl && data?.imagenUrl != ''){
            setUploadedImage(true);
        }
    }, []);

    const form = useForm({
        defaultValues: {
            titulo: banner?.titulo || '',
            subtitulo: banner?.subtitulo || '',
            descripcion: banner?.descripcion || '',
            imagenUrl: imagen || imgDefault,
            tipoSeccionId: tipoSeccion == "Banner"? 1: 4,
        }
    });

    async function handleChange(e: any) {
        setLoading(true);
        const target= e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if(file){
            setFile(e.target.files[0])
            setImagen(URL.createObjectURL(e.target.files[0]))
        }        
        setUploadedImage(true);
        setLoading(false);
    }

    const guardar = async (formData: any) => { 
        setLoading(true);
        if(file){
            let lastModified = file?.lastModified;
            const _formData = new FormData();
            _formData.append('image', file);
            _formData.append('name', `${formData.titulo}_${lastModified}`);
            const result = await UploadImgbb(_formData);            
            //const result = await uploadFile(file, `estudiomayocchi/banner/${formData.titulo}_${lastModified}`);
            formData = {
                ...formData,
                imagenUrl: result
            }
        }else{
            formData = {
                ...formData,
                imagenUrl: banner?.imagenUrl
            }
        }

        let btnConfirmar = document.getElementById(`btnModalInfoActualizar_${tipoSeccion}`);
        if(banner?.id && banner?.id > 0){
            formData = {
                ...formData,
                id: banner?.id
            }
            await UpdateSection(formData);
            btnConfirmar?.click();
            setLoading(false);
        }
        else{
            await InsertSection(formData);
            btnConfirmar?.click();
            setLoading(false);
        }
    }
    
    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;

    return (
        <div className={style.adminBanner}>                         
            <form onSubmit={handleSubmit(guardar)} className="row">
                <section className="col-12 col-md-6 col-lg-6 px-2 my-3">
                    <ul>
                        {tipoSeccion == "Banner"?<li className="d-flex align-items-center gap-3">
                            <label htmlFor="titulo" className={style.labelText}>Título</label>
                            <input type="text" {...register("titulo")} id="titulo" placeholder="Ingrese un titulo" 
                                className={`form-control my-2 border border-primary ${style.formControlText}`} />                          
                        </li>:''}
                        {errors.titulo?.type === 'required' && <p className="text-danger"> El campo es requerido</p>}   
                                      
                        {tipoSeccion == "Banner"?<li className="d-flex align-items-center gap-3 d-none">
                            <label htmlFor="subtitulo" className={style.labelText}>Subtítulo</label>
                            <input type="text" {...register("subtitulo")} id="subtitulo" placeholder="Ingrese un subtitulo" 
                                className={`form-control my-2 border border-primary ${style.formControlText}`} />                          
                        </li>:''}
                                      
                        {tipoSeccion == "Banner"?<li className="d-flex align-items-center gap-3">
                            <label htmlFor="descripcion" className={style.labelText}>Descripción</label>
                            <textarea {...register("descripcion")} id="descripcion" placeholder="Ingrese una descripción" 
                                className={`form-control my-2 border border-primary ${style.formControlText}`} />                          
                        </li>:''}
                        <li className="d-flex align-items-center gap-3">
                            <label htmlFor="descripcion" className={style.labelText}>Imagen</label>
                            <div className="w-100">
                                <input className={`form-control my-2 border border-primary bg-dark text-light ${style.formControlFile}`} type="file" 
                                    id="formFile" {...register("imagenUrl")} name="imagen" onChange={handleChange} accept="image/*"/>
                            </div>
                        </li>                               
                        {!uploadedImage && <p className="text-danger w-100 text-center"> Imágen requerida</p>}   
                    </ul>
                    <div className="col-md-12 d-flex justify-content-around my-5 gap-5">
                        <button type="button" id="btnBackSeccion" className="btn btn-outline-info" onClick={() => goBack()}><FaChevronLeft></FaChevronLeft></button>
                        <button type="submit" id="btnSubmitSeccion" className={`${style.boton}`} disabled={!uploadedImage}>Guardar</button>
                    </div>  
                </section>
                <section className="col-12 col-md-6 col-lg-6 p-2 my-3 h-100">
                    <img src={imagen || imgDefault} alt="banner" className={style.imgBanner}/>
                </section>    
            </form>
            <MyModalInfo idButton={`btnModalInfoActualizar_${tipoSeccion}`} titulo={"Confirmacion"} mensaje={`${tipoSeccion} actualizado con éxito`} headerColor="success" show={true}></MyModalInfo>
            {loading? <SpinnerLoading></SpinnerLoading>: ''}
        </div>
    )
}