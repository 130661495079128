import { Info } from '../../../interfaces/info';
import style from './style.module.css'

export default function TopSection(){

    const infoSection: Info =  {
        id: 1,
        title: 'Administración',
        subtitle: 'Panel de control',
        text: ``,
        imagen: ''
    };

    const logOut = () => {
        localStorage.removeItem("token");
        window.location.reload();
    }

    return (
        <div>
        <div className={style.headerBackground}></div>
            <div className={style.topSection}>
                <div className={`${style.sectionTitle}`}>
                    <h2 className={` ${style.title}`}>
                        {infoSection?.title}
                    </h2>
                    <span className={` ${style.subtitle}`}>
                        {infoSection?.subtitle}
                    </span>
                    <button className="my-3" onClick={logOut} >Cerrar Sesión</button>
                </div>   
            </div>         
        </div>
    )   
}