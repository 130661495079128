import Header from "../../components/header";
import { useEffect, useState } from "react";
import { NavItemActive } from "../../interfaces/nav-item";
import Footer from "../../components/footer";
import Copyright from "../../components/copyright";
import ReturnTop from "../../components/return-top";
import MapFooter from "../../components/map-footer";
import TopSection from "./top-section";
import ContactoSection from "./contacto-section";
import WhatsappButton from "../../components/whatsapp-button";

export default function Contacto(){
    const [styleTop, setStyleTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
          window.removeEventListener('scroll', onScroll)
        }
    }, []);

    const onScroll = (e: Event) => {
        const window = e.currentTarget as Window
        if (window.scrollY > 0) {
            setStyleTop(true);
        }
        else {
            setStyleTop(false);
        }
    }
    
    const navItemActive = NavItemActive.Contacto;
    return (
        <div>
            <Header navItemActive={navItemActive}></Header>
            <TopSection></TopSection>
            <ContactoSection></ContactoSection>
            <MapFooter></MapFooter>
            <Footer></Footer>
            <Copyright></Copyright>
            {/* {styleTop? <ReturnTop></ReturnTop> : ''} */}
            <WhatsappButton></WhatsappButton>
        </div>
    )
}