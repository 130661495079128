import style from "../our-projects/style.module.css";
import { useEffect, useState } from "react";
import { Edificio } from "../../interfaces/edificio";
import imgDefault from "../../media/img/bannerEmpty.jpg";
import { Obra } from "../../interfaces/obra";
import { useNavigate } from "react-router";

interface CardEdificioProps{
    data: Obra;
    columns?: string;
}

export default function CardEdificio({data, columns}: CardEdificioProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagen, setImagen] = useState<any>();

    useEffect(() => {
        setEdificio(data);
        if (data?.imagenes?.length && data?.imagenes?.length > 0){
            let imagenPrincipal = data?.imagenes.find(o => o.seccionId == 1);
            setImagen(imagenPrincipal?.url);
        } 
    }, []);  
    
    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/propiedad/?id=${edificio?.id}`);
    }
    
    const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {        
        event.currentTarget.src = "https://placehold.co/400x600?text=...";
    };
    
    return (
        <div className={`col-lg-${columns} col-md-6 mb-5 d-flex justify-content-center ${style.item} ${style.firstImage}`}>
            <div className={style.projectList}>
                <img src={imagen || "https://placehold.co/400x600?text=x"} alt="Project" id={edificio?.id?.toString()!} onError={imageOnLoadHandler}/>
                <div className={style.overlayWrap} onClick={redirect}>
                    <h5>{edificio.nombre}</h5>
                    <span>{edificio?.direccion} | {edificio?.ciudad}</span>
                    {/* <a className={style.stretchedLink} href={`#/propiedad/?id=${edificio?.id}`}><svg className="svg-inline--fa fa-play fa-w-14" aria-hidden="true" data-prefix="fas" data-icon="play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg></a> */}
                </div>
            </div>
        </div>
    )
}