import { Info } from "../interfaces/info";
import imageCalidad from "../media/icons/nosotros/badge_beige.png";
import imageColaboracion from "../media/icons/nosotros/handshake_beige.png";
import imagePasion from "../media/icons/nosotros/house_beige.png";

export const MockValores: Info[] = 
[
    {
        id: 1,
        title: 'CONFIANZA',
        subtitle: '',
        text: ``,
        imagen: imageColaboracion
    },
    {
        id: 2,
        title: 'SEGURIDAD',
        subtitle: '',
        text: ``,
        imagen: imageCalidad
    },
    {
        id: 3,
        title: 'PASIÓN',
        subtitle: '',
        text: ``,
        imagen: imagePasion
    },
]