import style from "./style.module.css";

export default function Copyright(){
    return (
        <div className={style.copyright}>
            <div className="container">
                <div className={style.floatLeft}>
                    © Estudios Mayocchi 2023. <p className="d-none d-md-block">Todos Los Derechos Reservados</p>
                </div>
                <a href="https://wa.me/+5492216104161" target="_blank" className={style.floatRight}>
                    DigitalProject
                    {/* <a href=""><img src={misticaLogo} alt="Mistica Logo"/></a> */}
                </a>
            </div>
        </div>
    )
}