import { loadMapApi } from "../../../utils/googleMaps";
import style from "./style.module.css";
import { useEffect, useState } from "react";
import MapEdit from "../../../components/map-edit";
import { useForm } from "react-hook-form";

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
}

interface MapProps{
    latitud?: string;
    longitud?: string;
    guardarUbicacion: any;
}

export default function MapFooter({latitud, longitud, guardarUbicacion}: MapProps){
    const [lat, setLatitud] = useState<string>(latitud!);
    const [long, setLongitud] = useState<string>(longitud!);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    
    useEffect(() => {
        const googleMapScript = loadMapApi();
        setTimeout(() => {
            
            setScriptLoaded(true);
        }, 1000);
    }, []);

    const getLocationMap = (latitude: number, longitude: number) => {
        //console.log(latitud, longitud);
        setValue("latitud", latitude.toString());
        setValue("longitud", longitude.toString());
        setLatitud(latitude.toString())
        setLongitud(longitude.toString())
        guardarUbicacion(latitude, longitude)
    }

    const form = useForm({
        defaultValues: {
            latitud: lat || '',
            longitud: long || '',
        }
    })
    
    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;
    
    return (
        <div className={style.mapFooter}>
            <h2 className="container my-3">Ubicación</h2>
            <form>
                <label htmlFor="nombre" className="mb-1">Latitud
                    <input type="text" {...register("latitud")} id="latitud" placeholder="" 
                        className={`form-control border border-primary ${style.formControlNombre}`} disabled/>
                </label>
                <label htmlFor="nombre" className="mb-5">Longitud
                    <input type="text" {...register("longitud")} id="longitud" placeholder="" 
                        className={`form-control border border-primary ${style.formControlNombre}`} disabled/>
                </label>
            </form>
           {scriptLoaded && (
                <MapEdit
                  mapType={google.maps.MapTypeId.ROADMAP}
                  mapTypeControl={true}
                  getLocation={getLocationMap}
                  latitud={Number(lat)}
                  longitud={Number(long)}
                />                
           )}
        </div>
    );
}