import style from "./style.module.css";
import { Edificio } from "../../../interfaces/edificio";
import { useEffect, useState } from "react";
import CardEdificio from "../../../components/cardEdificio";
import { useNavigate } from "react-router";
import { Obra } from "../../../interfaces/obra";

interface ProjectsProps{
    data?: Obra[];    
}
export default function Projects({data}: ProjectsProps){
    const [edificios, setEdificios] = useState<Obra[]>(data!);

    useEffect(() => {
        setEdificios(data!);
    }, []);

    const navigate = useNavigate();

    const redirect = () => {
        navigate("/obras");
    }

    const renderEdificios = () => edificios?.map((v, i) => <CardEdificio data={v} key={i} columns={'6'}></CardEdificio>)
    
    return (
        <section className={style.ourProjects}>
            <div className="container-fluid">
                <div className="row">
                    <div className={`col-lg-6 ${style.imgContainer}`}>
                        <div className={`${style.sectionTitle} ${style.editableImg}`}>
                            <h2 className={` ${style.title}`} data-id="2412" data-clave="about-us-txt">
                                Obras destacadas
                            </h2>
                            <span className={` ${style.subtitle}`} data-id="2411" data-clave="about-us-tit">
                                Estamos construyendo
                            </span>
                        </div>
                        <p className={` ${style.textDescription}`} data-id="2413" data-clave="about-us-txttxt">
                            Si buscás un lugar para vivir y una inversión rentable, nuestras obras son para vos.
                        </p>
                        <div className="container d-flex justify-content-center align-items-end my-4">
                            <button className={style.buttonProyectos} onClick={redirect}>
                                VER PROYECTOS
                            </button>
                        </div>
                    </div>
                    <div className="row col-12 col-lg-5 mb-6">
                        {renderEdificios()}
                    </div>
                </div>
            </div>
        </section>
    )
}