import axios from "axios";
import { enviroment } from "../interfaces/enviroment";

const BASE_URL: string = "https://api.imgbb.com/1/upload?key=699e443b81e8893eef4a9c05eadb82c8"
const ENV_DEMO: boolean = enviroment.demo();

export const UploadImgbb2 = async (data: any): Promise<any> => {
    if (ENV_DEMO){
        return "Imagen subida con éxito";
    }
    const url = `${BASE_URL}`;
    let result;
    const response = await axios.post(url, data)
    .then(res => {
      return res.data.data.display_url;
    })
    .catch((error) => {
      console.log(error);
    });    
}

export const UploadImgbb = async (data: FormData): Promise<any> => {
  if (ENV_DEMO){
      return "Imagen subida con éxito";
  }
  const url = `${BASE_URL}`;
  let result;
  const response = await fetch(url, {
      method: "POST",
      headers:{
          //"Content-Type": "application/json",
          //"Authorization": `Bearer ${JwtToken}`
      },
      body: data 
  })
  result = await response.json();
  if(response.ok){      
      //console.log(result)
      //console.log(result.data)
      //return result.data.display_url;
      return result.data.display_url;
  }

  return undefined;
}