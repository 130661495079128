import style from "./style.module.css";
import { useEffect, useState } from "react";
import { Obra } from "../../../interfaces/obra";
import { useForm } from "react-hook-form";
import imgDefault from "../../../media/img/bannerEmpty.jpg";
import { ArchivoImagen } from "../../../interfaces/archivoImagen";
import { FaTrashCan } from "react-icons/fa6";

interface DepartmentsProps{
    data: Obra;
    mensaje?: string; 
    uploadImage?: any;  
    guardarDepartments: any;
}

export default function Departments({data, mensaje, uploadImage, guardarDepartments}: DepartmentsProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [imagenes, setImagenes] = useState<ArchivoImagen[] | undefined>(edificio?.imagenes?.filter(e => e.seccionId == 3));
    const [file1, setFile1] = useState<any>();
    const [file2, setFile2] = useState<any>();
    const [file3, setFile3] = useState<any>();
    const [file4, setFile4] = useState<any>();
    const [uploadfile1, setUploadFile1] = useState<any>();
    const [uploadfile2, setUploadFile2] = useState<any>();
    const [uploadfile3, setUploadFile3] = useState<any>();
    const [uploadfile4, setUploadFile4] = useState<any>();

    useEffect(() => {
        setEdificio(data);
        let imagenesDepartamentos = edificio?.imagenes?.filter(e => e.seccionId == 3);
        setImagenes(imagenesDepartamentos);       
        setImagesObra();     
    }, []);  

    const form = useForm({
        defaultValues: {
            imagen1: file1 || imgDefault,
            imagen2: file2 || imgDefault,
            imagen3: file3 || imgDefault,
            imagen4: file4 || imgDefault,
            descripcionDepartamentos: edificio?.descripcionDepartamentos || '',
        }
    })

    async function setImagesObra(){
        if(imagenes && imagenes.length > 0){
            for (let index = 0; index < imagenes.length; index++) {
                await setImageFile((index+1).toString(), imagenes[index].url, false);                
            }
        }
    }

    async function handleChange(e: any) {
        e.preventDefault();
        let inputId = e.currentTarget.id;        
        const target= e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        
        if(file){
            await setImageFile(inputId[inputId.length-1], URL.createObjectURL(e.target.files[0]), true, (e.target.files))
        }
    }

    async function setImageFile(inputId: string, url: any, upload: boolean, file?: any){
        switch (inputId) {
            case '1':
                await setFile1(url);
                if(upload) { setUploadFile1(file) };
                break;
            case '2':
                await setFile2(url);
                if(upload) { setUploadFile2(file) };
                break;
            case '3':
                await setFile3(url);
                if(upload) { setUploadFile3(file) };
                break;
            case '4':
                await setFile4(url);
                if(upload) { setUploadFile4(file) };
                break;        
            default:
                break;
        }
    }

    const removeImg = async (e: any) => {
        let inputId = e.currentTarget.id;
        await setImageFile(inputId, undefined, false);
    }

    const guardar = (formData: any) => {
        formData = {
            ...formData,
            imagen1: uploadfile1? uploadfile1: file1,
            imagen2: uploadfile2? uploadfile2: file2,
            imagen3: uploadfile3? uploadfile3: file3,
            imagen4: uploadfile4? uploadfile4: file4,
        }
        guardarDepartments(formData);
    }

    const { register, formState: {errors}, watch, handleSubmit, setValue } = form;

    return (
        <section className={style.detailSection}>
            <div className="container-fluid">
                <form onSubmit={handleSubmit(guardar)}>
                <div className="row">                   
                    <div className="row col-lg-5 d-flex justify-content-center align-items-start">
                        <div className="col-12 col-md-6 mb-2">
                            <div className="d-flex gap-1">
                                <input className="form-control bg-dark text-light border border-primary" type="file" 
                                    id="formFile1" {...register("imagen1")} onChange={handleChange} accept="image/*"/>
                                <button id="1" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                            </div>
                            <img className="img-modal" src={file1 || imgDefault} alt={edificio?.nombre}  
                            width="100%" height="100%"
                            onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=imgDefault;}}/>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                            <div className="d-flex gap-1">
                                <input className="form-control bg-dark text-light border border-primary" type="file" 
                                    id="formFile2" {...register("imagen2")} onChange={handleChange} accept="image/*"/>
                                <button id="2" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                            </div>
                            <img className="img-modal" src={file2 || imgDefault} alt={edificio?.nombre}  
                            width="100%" min-height=""
                            onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=imgDefault;}}/>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                            <div className="d-flex gap-1">
                                <input className="form-control bg-dark text-light border border-primary" type="file" 
                                    id="formFile3" {...register("imagen3")} onChange={handleChange} accept="image/*"/>
                                <button id="3" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                            </div>
                            <img className="img-modal" src={file3 || imgDefault} alt={edificio?.nombre}  
                            width="100%" min-height=""
                            onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=imgDefault;}}/>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                            <div className="d-flex gap-1">
                                <input className="form-control bg-dark text-light border border-primary" type="file" 
                                    id="formFile4" {...register("imagen4")} onChange={handleChange} accept="image/*"/>
                                <button id="4" type="button" className="btn btn-danger" onClick={removeImg}><FaTrashCan color="white" size={32}></FaTrashCan></button>
                            </div>
                            <img className="img-modal" src={file4 || imgDefault} alt={edificio?.nombre}  
                            width="100%" min-height=""
                            onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src=imgDefault;}}/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className={style.detailBox}>
                            <div className={style.sectionTitle}>
                                <h2>Departamentos</h2>
                            </div>                            
                            <div className={`${style.scrollBox} ${style.customScrollbar}`}>
                                <div id="mCSB_1" className={`${style.customLight}`} tabIndex={0}>
                                    <div id="mCSB_1_container" className={style.customContainer} dir="ltr">
                                        <div className={style.scrollNoneBox}>
                                            <textarea {...register("descripcionDepartamentos", {required: true})} id="descripcionDepartamentos" placeholder="Ingrese una descripcion" className={`form-control border border-primary ${style.formControlNombre}`} />
                                            {errors.descripcionDepartamentos?.type === 'required' && <span className="text-danger mt-2"> El campo descripcion es requerido</span>}     
                                        </div>
                                    </div>                             
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-5">
                                <button type="submit" id="departmentsSubmit" className={`${style.boton}`} hidden>Guardar</button>
                            </div>                                                       
                        </div>
                    </div>                    
                </div>
                </form>
            </div>
        </section>
    )
}