import style from "./style.module.css";
import { useEffect, useState } from "react";
import DetailProjects from "../detail-projects";
import MyModalContacto from "../../../components/modal";
import { Obra } from "../../../interfaces/obra";

interface DepartmentsProps{
    data: Obra;
    mensaje?: string;   
}

export default function Departments({data, mensaje}: DepartmentsProps){
    const [edificio, setEdificio] = useState<Obra>(data);
    const [message, setMessage] = useState(`Hola, quiero recibir más información sobre el emprendimiento ${edificio?.nombre}. ¡Muchas gracias!`);

    useEffect(() => {
        setEdificio(data);
        setMessage(`Hola, quiero recibir más información sobre el emprendimiento ${edificio?.nombre}. ¡Muchas gracias!`);     
    }, []);  


    return (
        <section className={style.detailSection}>
            <div className="container-fluid">
                <div className="row">  
                    <div className="col-lg-5">
                        {edificio.imagenes? <DetailProjects data={edificio?.imagenes} idSeccion={3}></DetailProjects>: ''}
                    </div>                  
                    <div className="col-lg-7">
                        <div className={style.detailBox}>
                            <div className={style.sectionTitle}>
                                <h2>Departamentos</h2>
                            </div>
                            <div className={`${style.scrollBox} ${style.customScrollbar}`}>
                                <div id="mCSB_1" className={`${style.customLight}`} tabIndex={0}>
                                    <div id="mCSB_1_container" className={style.customContainer} dir="ltr">
                                        <p>{edificio?.descripcionDepartamentos}</p>
                                    </div>                             
                                </div>
                            </div>
                            <div className="container d-flex justify-content-center align-items-end my-5">
                                <MyModalContacto show={true} mensaje={message}></MyModalContacto>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    )
}